import { HTMLAttributes } from "react";

interface HeadlineProps extends HTMLAttributes<HTMLHeadingElement> {
  level?: 1 | 2 | 3 | 4 | 5;
}

const Headline = ({ level = 3, className = "", children, ...props }: HeadlineProps) => {
  const Tag: keyof React.JSX.IntrinsicElements = `h${level}`;
  return (
    <div className="text-center">
      <Tag
        {...props}
        className={`
          headline text-sm inline-flex justify-center relative pb-1 mb-2 w-max before:content-[''] before:w-full before:h-px before:bg-primary before:absolute before:-bottom-0
          ${className}
        `}
      >
        {children}
      </Tag>
    </div>
  );
};

export default Headline;

export const SubHeadline = ({ level = 4, className = "", children, ...props }: HeadlineProps) => {
  const Tag: keyof React.JSX.IntrinsicElements = `h${level}`;
  return (
    <Tag {...props} className={`sub-headline text-xs text-text-secondary capitalize text-left ${className}`}>
      {children}
    </Tag>
  );
};
