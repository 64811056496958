import { Timestamp } from "firebase/firestore";

export interface ExpenseInterface {
  id: string;
  date: {
    bought: Timestamp;
    created: Timestamp;
  };
  price: Price;
  expense: Expense;
  relationFrom?: Person;
  relationFor?: Person;
  tags?: Tag[];
}

export type Expense = string;

export type Tag = string;
export type BaseTagGroupNameType = (typeof BaseTagGroupName)[number];
export const BaseTagGroupName = ["household", "bills", "entertainment", "transport", "wellness", "misc"] as const;
export type TagGroup = string[];
export type TagGroups = { [name in string]: Tag[] };

export type Person = string;

export type Price = number;

export interface PieProps {
  name: string;
  value: number;
}
export interface ChartData {
  name: string;
  value: number;
}
export const householdTags: TagGroup = [
  "Groceries",
  "Cleaning supplies",
  "Toiletries",
  "Kitchen utensils",
  "Furniture",
  "Curtains",
  "Bedding",
  "Light bulbs",
  "Tools",
];
export const billsTags: TagGroup = ["Electricity", "Water", "Internet", "Phone", "Mobile Data"];
export const entertainmentTags: TagGroup = [
  "Movie Ticket",
  "Concert",
  "Streaming subscription",
  "Video game",
  "Book",
  "Eating out",
  "Takeout",
  "Order",
  "Travel",
];
export const transportTags: TagGroup = ["Fuel", "Car maintenance", "Public transport", "Taxi/Uber/Grab", "Car insurance", "Parking"];
export const wellnessTags: TagGroup = ["Gym", "Health insurance", "Medical bills", "Pharmacy", "Therapist", "Massage"];
export const miscTags: TagGroup = ["Gifts", "Clothing", "Charity", "Subscriptions", "Pet expenses", "Repairs", "Home decor"];

export const allBaseTagCategories: TagGroups = {
  household: householdTags,
  bills: billsTags,
  entertainment: entertainmentTags,
  transport: transportTags,
  wellness: wellnessTags,
  misc: miscTags,
};
