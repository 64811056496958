import { memo } from "react";
import FoodList from "./FoodList";

export const Food = () => (
  <>
    <FoodList />
  </>
);

export default memo(Food);
