import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useFlainContext } from "../../App";
import { getIcon } from "../../data/icons";
import { FlainContextType } from "../../data/types";
import routes, { PathRoute } from "../routes";

const Settings = () => {
  const { user } = useFlainContext() as FlainContextType;

  if (!user) return null;

  return (
    <div className="mx-auto w-full max-w-md py-4 overflow-y-auto flex flex-col gap-px">
      {Object.values(routes.index.children.settings.children)
        .slice(1)
        .map((route: PathRoute) => (
          <Link
            key={route.path}
            to={route.path}
            className="w-full p-4 text-sm rounded-sm cursor-pointer bg-surface transition-[colors,padding] hover:pr-6 duration-300 flex items-center gap-4"
          >
            <Icon icon={getIcon(route.icon)} fontSize={24} />
            <div className="flex-1">{route.text}</div>
            <Icon icon={getIcon("chevron-right")} />
          </Link>
        ))}
    </div>
  );
};

export default Settings;
