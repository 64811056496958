import { Icon } from "@iconify/react";
import L, { LatLngLiteral, Map } from "leaflet";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import ic from "leaflet/dist/images/marker-icon.png";
import shadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import Button from "../../components/Button";
import Container from "../../components/Container";
import { getIcon } from "../../data/icons";

const Location = () => {
  //const { user } = useFlainContext() as FlainContextType;
  const minDistanceToUpdate = 2;
  const map = useRef<Map>(null);
  const [accuracy, setAccuracy] = useState<number>();
  const [position, setPosition] = useState<GeolocationPosition | undefined>();
  const [lastPosition, setLastPosition] = useState<
    GeolocationPosition | undefined
  >();
  const [distance, setDistance] = useState(0);
  const [loadingCoords, setLoadingCoords] = useState(true);
  const [loadingMap, setLoadingMap] = useState(true);

  const icon = L.icon({
    iconUrl: ic,
    iconRetinaUrl: iconRetina,
    shadowUrl: shadow,
  });

  const options: PositionOptions = {
    enableHighAccuracy: true,
    maximumAge: 10000,
    timeout: 10000,
  };

  const success = (p: GeolocationPosition) => {
    console.debug("accuracy: ", p.coords.accuracy);
    setAccuracy(Math.round(p.coords.accuracy)); 
    if (p.coords.accuracy > 20) return;
    setPosition(p);
  };

  const error = (e: unknown) => {
    console.error("Sorry, no position available.", e);
  };

  useEffect(() => {
    let watchID: number | undefined;
    if ("geolocation" in navigator) {
      console.log("geolocation available");
      watchID = navigator.geolocation.watchPosition(success, error, options);
    } else {
      console.log("geolocation not available");
    }

    return () => {
      if (watchID) navigator.geolocation.clearWatch(watchID);
    };
    // eslint-disable-next-line
  }, []);

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  /* const stopLocation = () => {
    if (watchID) {
      navigator.geolocation.clearWatch(watchID);
    }
  }; */

  const convert = (coords: GeolocationPosition): LatLngLiteral => {
    return { lat: coords.coords.latitude, lng: coords.coords.longitude };
  };

  useEffect(() => {
    if (position) {
      if (!lastPosition) {
        // Initialize lastPosition if it's undefined
        setLastPosition(position);
      } else {
        const walkedDistance = Math.round(
          L.latLng(convert(position)).distanceTo(convert(lastPosition))
        );

        if (walkedDistance >= minDistanceToUpdate) {
          if (map.current) L.marker(convert(position)).addTo(map.current);
          setDistance((prevDistance) => prevDistance + walkedDistance); // Accumulate distance
          setLastPosition(position); // Only update after walking enough distance
          // Update the location in the database here
        }
      }

      if (map.current) {
        map.current.setView(convert(position));
      }

      setLoadingCoords(false);
    }
    // eslint-disable-next-line
  }, [position]);

  useEffect(() => {
    console.log("distance: ", distance);
  }, [distance]);

  /* const uploadPosition = async () => {
    if (!user) return;
    await addDoc(collection(db, "users", user.uid), {})
      .then(() => {})
      .catch((error) => console.error(error.code, error.message));
  }; */

  return (
    <Container background>
      <p>Distance: {distance}</p>
      <p>Accuracy: {accuracy}</p>
      <Button icon="my-location" onClick={getLocation}>
        Current Location
      </Button>
      {loadingCoords && loadingMap ? (
        <div className="w-full aspect-video bg-background flex items-center justify-center">
          <Icon icon={getIcon("loading")} />
        </div>
      ) : (
        position && (
          <MapContainer
            whenReady={() => setLoadingMap(false)}
            ref={map}
            center={convert(position)}
            zoom={16}
            scrollWheelZoom={false}
            className="w-full aspect-video"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <Marker position={convert(position)} icon={icon}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>
        )
      )}
    </Container>
  );
};

export default Location;
