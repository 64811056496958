import Container from "../components/Container"

const NotFound = () => {
  return (
    <Container className="text-center">
      <h1 className="text-5xl font-bold">404</h1>
    </Container>
  )
}

export default NotFound