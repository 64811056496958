import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Container from "../../components/Container";
import { getIcon } from "../../data/icons";
import routes, { PathRoute } from "../routes";
import ChartExpenses from "./components/ChartExpensesMonth";
import UpcomingReminders from "./components/UpcomingReminders";
import Updates from "./components/Updates";

const Home = () => {
  return (
    <>
      <Container background>
        <div className="grid grid-cols-4 sm:grid-cols-4 gap-2">
          {routes.index.children &&
            Object.values(routes.index.children).map((route) => {
              if (!route.hiddenFromMenus && !route.removedFromRoutes && "path" in route) return <HomeTile key={route.path} route={route} />;
              else return null;
            })}
        </div>
      </Container>
      <UpcomingReminders />
      <ChartExpenses period={"Month"} />
      <ChartExpenses period={"Year"} />
      <Updates />
    </>
  );
};

export default Home;

export const HomeTile = ({ route }: { route: PathRoute }) => {
  const { icon, text } = route;
  return (
    <Link
      to={route.path}
      className="rounded-sm aspect-square bg-surface/50 p-2 flex gap-2 items-center justify-center text-center border border-border/50 hover:bg-primary transition-colors duration-300 group relative overflow-hidden text-text-secondary hover:text-primaryContrast"
    >
      <Icon icon={getIcon(icon)} fontSize={24} className="absolute" />
      <div className="opacity-0 translate-y-full absolute bottom-1 group-hover:opacity-100 group-hover:translate-y-0 transition-[opacity,transform] duration-300">
        <h2 className="text-xs">{text}</h2>
      </div>
    </Link>
  );
};
