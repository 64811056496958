import { Icon } from "@iconify/react";
import React, { Dispatch, memo, ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import { getIcon } from "../data/icons";

const Overlay = ({
  children,
  isOpen,
  setIsOpen,
  overflow = false,
}: {
  id: string;
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
  overflow?: boolean;
}) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "visible";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isOpen]);

  if (!isOpen) return null;
  return (
    document.getElementById("root") &&
    createPortal(
      <div className="overlay fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center m-0 z-20 overflow-hidden p-2">
        <div className="absolute bg-background bg-opacity-95 left-0 top-0 right-0 bottom-0 w-full h-full cursor-pointer" onClick={() => setIsOpen(false)} />
        <div className={`bg-surface w-full max-w-sm rounded-lg max-h-full ${overflow ? "" : "overflow-y-auto"} relative`}>
          {children}
          <div onClick={() => setIsOpen(false)} className="absolute top-1 right-1 z-20 cursor-pointer text-xl drop-shadow-[0_0_1px_rgba(0,0,0,0.3)]">
            <Icon icon={getIcon("x")} />
          </div>
        </div>
      </div>,
      document.getElementById("root")!
    )
  );
};

export default memo(Overlay);
