const locale = "en-MY"; //(navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language || ["en"];

export const formattedDateCustom = (timestamp: number | Date, options: Intl.DateTimeFormatOptions): string => {
  return new Intl.DateTimeFormat(locale, options).format(new Date(timestamp)).replaceAll("/", ".");
};

export const numberToDate = (dayOfYear: number): Date => {
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(currentYear, 0, 1);
  const resultDate = new Date(startOfYear.setDate(startOfYear.getDate() + dayOfYear - 1));
  return resultDate;
};

export const formattedDateWithTime = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  })
    .format(new Date(timestamp))
    .replaceAll("/", ".");
};

export const formattedDate = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
    .format(new Date(timestamp))
    .replaceAll("/", ".");
};

export const formattedMonthAndYear = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    month: "long",
    year: "numeric",
  })
    .format(new Date(timestamp))
    .replaceAll("/", ".");
};

export const getDayOfWeek = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    weekday: "long",
  }).format(new Date(timestamp));
};

export const getDayOfWeekFromNumber = (day: number) => {
  const dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return dayOfWeek[day];
};

export const lastDayOfMonth = (year: number, month: number): number => new Date(year, month + 1, 0).getDate();

export const timeZero = (date: Date) => {
  const d = date;

  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);

  return d;
};

export const datesOfWeek = (date: Date) => {
  const week: Date[] = [];
  const d = date;

  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);

  d.setDate(d.getDate() - d.getDay());

  for (let i = 0; i < 7; i++) {
    week.push(new Date(d));
    d.setDate(d.getDate() + 1);
  }

  return week;
};

export const toDateInputValue = (dateObject: Date) => {
  const local = new Date(dateObject);
  local.setMinutes(dateObject.getMinutes() - dateObject.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};

export const getDaysOfMonth = (date: Date = new Date()) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { first: firstDay, last: lastDay };
};
