import { Icon } from "@iconify/react";
import { deleteDoc, doc } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref } from "firebase/storage";
import { memo, useState } from "react";
import { toast } from "react-toastify";
import placeholder from "../../assets/placeholder.svg";
import Button from "../../components/Button";
import Headline from "../../components/Headline";
import Overlay from "../../components/Overlay";
import { getIcon } from "../../data/icons";
import { db, storage } from "../../firebase/firebase";
import { currency } from "../../helper/stringHelper";
import { Food } from "./types";

const FoodItem = ({ food }: { food: Food }) => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [image, setImage] = useState<string | undefined>();
  const [thumb, setThumb] = useState<string | undefined>();

  const imageRef = food.image?.path ? ref(storage, food.image.path) : undefined;
  const thumbRef = food.image?.thumb.path ? ref(storage, food.image.thumb.path) : undefined;

  const deleteFood = async (id: string) => {
    await deleteDoc(doc(db, "food", id))
      .then(async () => {
        if (imageRef) await deleteObject(imageRef).catch((error) => console.error(error));
        if (thumbRef) await deleteObject(thumbRef).catch((error) => console.error(error));
        toast(`Deleted food "${food.name}"`);
      })
      .catch((error) => console.error(error.message));
  };

  if (imageRef) {
    getDownloadURL(imageRef)
      .then((url) => setImage(url))
      .catch((error) => console.error(error));
  }

  if (thumbRef) {
    getDownloadURL(thumbRef)
      .then((url) => setThumb(url))
      .catch((error) => console.error(error));
  }

  if (!food) return null;
  return (
    <>
      <div className="bg-background p-2 rounded-sm w-full flex flex-col gap-1 cursor-pointer" onClick={() => setOverlayOpen(true)}>
        <div className="rounded-sm aspect-video overflow-hidden">
          <img src={thumb || placeholder} className="w-full h-full object-cover object-center" alt={food.name} />
        </div>
        <div className="flex-1 flex justify-between items-baseline gap-2">
          <p className="text-sm">{food.name}</p>
          <p className="inline-flex gap-0.5 items-baseline">
            <span className="text-xs">RM</span>
            <span className="text-2xl font-semibold">{currency(food.price)}</span>
          </p>
        </div>
      </div>

      <Overlay id={`overlay-food-item`} isOpen={overlayOpen} setIsOpen={setOverlayOpen}>
        <div className="flex flex-col items-center">
          <div className="rounded-sm aspect-video overflow-hidden">
            <img src={image || placeholder} className="w-full h-full object-cover object-center" alt={food.name} />
          </div>

          <div className="flex-1 flex flex-col justify-between items-center gap-2 px-2 my-2 w-full text-sm">
            <Headline>{food.name}</Headline>
            <p className="inline-flex gap-0.5 items-baseline">
              <span
                className="
                text-5xl font-semibold relative
                before:content-['RM'] before:text-xs before:text-text-secondary before:absolute before:bottom-px before:left-0 before:-translate-x-full before:-translate-y-0.5
                "
              >
                {currency(food.price)}
              </span>
            </p>

            {food.link && (
              <>
                <hr className="my-0" />
                <a href={`//${food.link}`} target="_blank" rel="noreferrer" className="text-primary flex items-center gap-2">
                  Link <Icon icon={getIcon("external-link")} />
                </a>
              </>
            )}

            <hr className="my-0" />
            <Button
              className="self-center"
              onClick={() => {
                deleteFood(food.id);
                setOverlayOpen(false);
              }}
              icon="trash"
              small
            >
              Delete
            </Button>
          </div>
        </div>
      </Overlay>
    </>
  );
};

export default memo(FoodItem);
