import { Icon } from "@iconify/react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useFlainContext } from "../../App";
import Container from "../../components/Container";
import Headline from "../../components/Headline";
import { getIcon, IconType } from "../../data/icons";
import { FlainContextType } from "../../data/types";
import { db } from "../../firebase/firebase";
import { formattedDateCustom } from "../../helper/dateHelper";
import ReminderItem from "./ReminderItem";
import { Reminder } from "./types";

const ReminderList = () => {
  const { user } = useFlainContext() as FlainContextType;
  const [reminders, setReminders] = useState<Reminder[]>([]);
  const [infoOpen, setInfoOpen] = useState(false);
  const [showTimeline, setShowTimeline] = useState(true);

  const getNextWeeklyOccurrence = useCallback(({ time, date, frequency }: Reminder): Date => {
    const now = new Date();
    const today = now.getDay();

    const targetDay = frequency === "Daily" ? today : date.day;
    const daysUntilNext = targetDay >= today ? targetDay - today : 7 + targetDay - today;

    const nextOccurrence = frequency === "Once" ? new Date(date.timestamp.seconds * 1000) : new Date(now);
    if (frequency !== "Once") nextOccurrence.setDate(now.getDate() + daysUntilNext);
    const [hours, minutes] = time.split(":").map(Number);
    nextOccurrence.setHours(hours, minutes, 0, 0);
    return nextOccurrence;
  }, []);

  useEffect(() => {
    if (!user) return;
    const q = query(collection(db, "reminders"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempReminders: Reminder[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as Reminder;
        tempReminders.push({ ...data, id: doc.id, nextOccurance: getNextWeeklyOccurrence(data) });
      });
      setReminders(tempReminders);
    });
    return () => unsubscribe();
  }, [user, getNextWeeklyOccurrence]);

  const dateToday = useMemo(() => new Date(new Date().setHours(0, 0, 0, 0)), []);
  const dateTomorrow = useMemo(() => {
    const tomorrow = new Date(dateToday);
    return new Date(tomorrow.setDate(dateToday.getDate() + 1));
  }, [dateToday]);

  const sortedReminders = useMemo(() => {
    return reminders.sort((a, b) => a.nextOccurance.getTime() - b.nextOccurance.getTime());
  }, [reminders]);

  return (
    <>
      <Container small noPadding>
        <div className="flex justify-end gap-2 px-2 text-xl">
          <button onClick={() => setShowTimeline(!showTimeline)} className={`transition-colors duration-300 ${showTimeline ? "opacity-100" : "opacity-50"}`}>
            <Icon icon={getIcon("calendar")} />
          </button>
          <button onClick={() => setInfoOpen(!infoOpen)} className={`transition-colors duration-300 ${infoOpen ? "opacity-100" : "opacity-50"}`}>
            <Icon icon={getIcon("info")} />
          </button>
        </div>
      </Container>

      <div
        className={`
            transition-all duration-500 overflow-hidden ease-in-out 
            ${infoOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}
          `}
      >
        <Container small background className="flex flex-wrap gap-3 items-center text-xs">
          <span className="inline-flex gap-3 items-center">
            <InfoItem icon="check">Done</InfoItem>
            <InfoItem icon="x">Not done</InfoItem>
          </span>
          <span className="inline-flex gap-3 items-center">
            <InfoItem icon="notification">Subscribed</InfoItem>
            <InfoItem icon="notification-off">Not subscribed</InfoItem>
          </span>
          <InfoItem icon="calendar">Once: Reminder gets deleted when in the past and set to &quot;done&quot;.</InfoItem>
          <InfoItem icon="calendar">Daily: Repeats every day until deleted.</InfoItem>
          <InfoItem icon="calendar">Weekly: Repeats every week on the set day until deleted.</InfoItem>
        </Container>
      </div>

      <Container small>
        <div className={`flex flex-col gap-1`}>
          {sortedReminders.length ? (
            sortedReminders.map((reminder, index, array) => {
              const current = reminder.nextOccurance;
              const prev = index > 0 ? array[index - 1].nextOccurance : undefined;
              const dateCurrent = new Date(new Date(current).setHours(0, 0, 0, 0));

              return (
                <Fragment key={reminder.id}>
                  {index === 0 ||
                  !prev ||
                  current.getFullYear() !== prev.getFullYear() ||
                  current.getMonth() !== prev.getMonth() ||
                  current.getDate() !== prev.getDate() ? (
                    <div
                      className={`
                        flex flex-col items-center justify-center transition-all duration-500 overflow-hidden ease-in-out 
                        ${showTimeline ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}
                      `}
                    >
                      {index !== 0 && <div className="h-6 w-0 border-l border-border border-dotted my-1" />}
                      <Headline>
                        {dateToday.getTime() === dateCurrent.getTime() ? (
                          <>Today</>
                        ) : dateTomorrow.getTime() === dateCurrent.getTime() ? (
                          <>Tomorrow</>
                        ) : (
                          formattedDateCustom(current, { day: "numeric", month: "long", year: "numeric" })
                        )}
                      </Headline>
                      <div className="h-2 w-0 border-l border-border border-dotted my-1" />
                    </div>
                  ) : null}
                  <ReminderItem reminder={reminder} />
                </Fragment>
              );
            })
          ) : (
            <div className="text-center">Nothing to do.</div>
          )}
        </div>
      </Container>
    </>
  );
};

export default ReminderList;

const InfoItem = ({ icon, children }: { icon: IconType; children: string }) => (
  <div className="inline-flex items-start gap-1">
    <Icon icon={getIcon(icon)} className="shrink-0 mt-px text-sm" /> {children}
  </div>
);
