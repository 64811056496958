import { Icon } from "@iconify/react";
import { arrayRemove, arrayUnion, deleteField, doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { DragEvent, FormEvent, Fragment, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useFlainContext } from "../../App";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Input from "../../components/Input";
import { getIcon } from "../../data/icons";
import { FlainContextType } from "../../data/types";
import { db } from "../../firebase/firebase";
import { allBaseTagCategories, Tag, TagGroup, TagGroups } from "../finances/types";

const SettingsTags = () => {
  const [allTagGroupNames, setAllTagGroupNames] = useState<string[]>([]);
  type AllTagGroupNames = (typeof allTagGroupNames)[number];

  const resetTagOpenState = (openTag?: AllTagGroupNames): { [name in AllTagGroupNames]: boolean } => {
    const keys = [...allTagGroupNames, undefined] as AllTagGroupNames[];
    return Object.fromEntries(keys.map((key) => [key, key === openTag])) as { [name in AllTagGroupNames]: boolean };
  };

  const { user } = useFlainContext() as FlainContextType;
  const [tagGroups, setTagGroups] = useState<TagGroups>();
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [newTag, setNewTag] = useState<Tag>("");
  const [newTagOpen, setNewTagOpen] = useState<{ [name in AllTagGroupNames]: boolean }>(resetTagOpenState(undefined));
  const [newTagGroup, setNewTagGroup] = useState<string>("");

  const tagExists = useMemo(() => allTags.some((t) => t.toLowerCase() === newTag.toLowerCase()), [allTags, newTag]);
  const validNewTag = useMemo(() => Boolean(newTag.trim()) && !tagExists, [newTag, tagExists]);

  const resetAllTags = (open?: AllTagGroupNames) => setNewTagOpen(resetTagOpenState(open));

  const ref = doc(db, "meta", "tags");

  useEffect(() => {
    if (!user) return;
    const unsub = onSnapshot(ref, (doc) => {
      const data = doc.data() as TagGroups;

      const groups = Object.fromEntries(Object.entries(data).sort()) as TagGroups;
      const tags = Object.values(data).flat() as Tag[];
      const groupNames = Object.keys(data).sort((a, b) => a.localeCompare(b)) as Tag[];

      setTagGroups(groups);
      setAllTags(tags.sort((a, b) => a.localeCompare(b)));
      setAllTagGroupNames(groupNames);
    });

    return () => unsub();
    // eslint-disable-next-line
  }, [user]);

  const setDefaultTags = async () => {
    await setDoc(ref, allBaseTagCategories)
      .then(() => toast("Reset Tags"))
      .catch((error) => console.error("failed to reset tags: ", error));
  };

  const addNewTagGroup = (e: FormEvent) => {
    e.preventDefault();
    if (newTagGroup.trim())
      updateDoc(ref, { [newTagGroup.trim()]: [] })
        .then(() => {
          toast(
            <>
              Added Group <span className="text-text-secondary capitalize">{newTagGroup}</span>
            </>
          );
          setNewTagGroup("");
        })
        .catch((error) => console.error(error));
  };

  const removeTagGroup = (group: AllTagGroupNames) => {
    updateDoc(ref, { [group]: deleteField() })
      .then(() => {
        toast(
          <>
            Removed Group <span className="text-text-secondary capitalize">{group}</span>
          </>
        );
      })
      .catch((error) => console.error(error));
  };

  const addTag = async (tag: Tag, group: AllTagGroupNames) => {
    await updateDoc(ref, {
      [group]: arrayUnion(tag.trim()),
    })
      .catch((error) => console.error(error))
      .then(() => {
        setNewTag("");
        toast(
          <span>
            Added Tag <span className="text-text-secondary">{tag}</span>
          </span>
        );
      });
  };

  const removeTag = async (tag: Tag, group: AllTagGroupNames) => {
    await updateDoc(ref, {
      [group]: arrayRemove(tag),
    })
      .catch((error) => console.error(error))
      .then(() =>
        toast(
          <span>
            Removed <span className="text-text-secondary">{tag}</span> from <span className="text-text-secondary">{group}</span>
          </span>
        )
      );
  };

  const [draggedTag, setDraggedTag] = useState<Tag | undefined>();
  const [originalGroup, setOriginalGroup] = useState<string | undefined>();
  const [targetGroup, setTargetGroup] = useState<string | undefined>();

  const onDragOver = (event: DragEvent<HTMLElement>, group: string) => {
    event.preventDefault();
    if (targetGroup !== group) setTargetGroup(group);
  };

  const onDragEnter = (event: DragEvent<HTMLElement>, group: string) => {
    event.preventDefault();
    if (targetGroup !== group) setTargetGroup(group);
  };

  const onDragLeave = (event: DragEvent<HTMLElement>) => {
    event.preventDefault();
    setTargetGroup(undefined);
  };

  const onDrop = (event: DragEvent<HTMLElement>) => {
    event.preventDefault();
    if (!draggedTag || !originalGroup || !targetGroup || originalGroup === targetGroup) return;
    addTag(draggedTag, targetGroup);
    removeTag(draggedTag, originalGroup);
  };

  const onDragStart = (event: DragEvent<HTMLElement>, tag: Tag, group: string) => {
    event.dataTransfer.effectAllowed = "move";
    setDraggedTag(tag);
    setOriginalGroup(group);
  };

  const onDragEnd = () => {
    setDraggedTag(undefined);
    setTargetGroup(undefined);
    setOriginalGroup(undefined);
  };

  const deleteFieldClasses = ["border-red-600", "text-red-400"];

  return (
    <>
      <Container background small>
        <form onSubmit={addNewTagGroup}>
          <Input
            label="New Tag Group"
            id="new-tag-group"
            buttons={[{ icon: "send", disabled: !newTagGroup.trim() }]}
            value={newTagGroup}
            onChange={(e) => setNewTagGroup(e.target.value)}
            small
          />
        </form>
      </Container>
      {tagGroups ? (
        Object.entries(tagGroups).map(([n, t], i) => {
          const name = n as AllTagGroupNames;
          const tags = t as TagGroup;
          const open = newTagOpen[name];

          return (
            <Fragment key={`tag-group-${name}`}>
              <Container
                background
                small
                onDrop={onDrop}
                onDragEnter={(e) => onDragEnter(e, name)}
                onDragLeave={onDragLeave}
                onDragOver={(e) => onDragOver(e, name)}
                id={`tag-container-${name}`}
                className={`
                  transition-[border] duration-300 border rounded-sm ${i === 0 ? "" : ""}
                  ${targetGroup === name && originalGroup !== name ? "border-border" : "border-transparent"}
                `}
              >
                <div className="flex items-center gap-2 mb-2">
                  <h4 className="font-semibold text-text-secondary capitalize">{name}</h4>
                  <div className="flex-1 h-0 border-b border-border border-dotted" />
                  {open && (
                    <button onClick={() => removeTagGroup(name)}>
                      <Icon icon={getIcon("x-circle")} />
                    </button>
                  )}
                  <button onClick={() => resetAllTags(open ? undefined : name)} className="text-text-secondary">
                    <Icon icon={getIcon(open ? "edit-off" : "edit")} className={` ${open ? "" : ""}`} />
                  </button>
                </div>

                <div className="flex flex-col items-start gap-1">
                  {tags &&
                    tags?.map((tag: Tag) => (
                      <div
                        key={`tag-${name}-${tag.toLowerCase().replace(" ", "-")}`}
                        id={`tag-${name}-${tag.toLowerCase().replace(" ", "-")}`}
                        draggable
                        onDragStart={(e) => onDragStart(e, tag, name)}
                        onDragEnd={onDragEnd}
                        className={`
                        flex items-center overflow-hidden cursor-grab active:cursor-grabbing text-sm rounded-sm transition-[box-shadow,color] duration-300
                      `}
                      >
                        {tag}
                      </div>
                    ))}

                  {open && (
                    <>
                      <hr className={``} />
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (validNewTag) addTag(newTag, name);
                        }}
                        className="w-full"
                      >
                        <Input
                          id={`new-tag-${name}`}
                          label={`New ${name} Tag`}
                          buttons={[{ icon: "send", disabled: !validNewTag }]}
                          value={newTag}
                          onChange={(e) => setNewTag(e.target.value)}
                          small
                          dataList={{ list: allTags, onClick: setNewTag }}
                        />
                      </form>
                    </>
                  )}
                </div>
              </Container>

              <div
                onDragOver={(e: DragEvent<HTMLElement>) => {
                  e.preventDefault();
                }}
                onDragEnter={(e: DragEvent<HTMLElement>) => {
                  e.preventDefault();
                  const target = e.target as HTMLDivElement;
                  target.classList.add(...deleteFieldClasses);
                }}
                onDragLeave={(e: DragEvent<HTMLElement>) => {
                  e.preventDefault();
                  const target = e.target as HTMLDivElement;
                  target.classList.remove(...deleteFieldClasses);
                }}
                onDrop={(e: DragEvent<HTMLElement>) => {
                  e.preventDefault();
                  if (!draggedTag || !originalGroup) return;
                  removeTag(draggedTag, originalGroup);
                }}
                className={`
                  max-w-96 mx-auto  overflow-hidden transition-[max-height,_opacity] duration-500
                  ${draggedTag ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}
                `}
              >
                <div className="p-4 border border-border border-dashed rounded flex items-center justify-center transition-[border,color] duration-500">
                  <Icon icon={getIcon("trash")} />
                </div>
              </div>
            </Fragment>
          );
        })
      ) : (
        <Container>No Tags</Container>
      )}
      <Container background small>
        <div className="flex justify-center">
          <Button onClick={setDefaultTags} icon="tag-reset" small>
            Reset Tags to default
          </Button>
        </div>
      </Container>
    </>
  );
};

export default SettingsTags;
