
export interface UploadFood {
  name: string;
  price: number;
  type: FoodType;
  description?: string;
  image?: FoodImage;
  link?: string;
}

export interface FoodImage {
  name: string;
  path: string;
  thumb: {
    name: string;
    path: string;
  };
}

export interface Food extends UploadFood {
  id: string;
}

export interface FoodPlace {
  id?: string;
  name: string;
  url?: string;
}

export type FoodType = "takeout" | "order" | "cook";
export type FoodTypes = {
  [name in FoodType]: string;
};
export const foodTypes: FoodTypes = {
  order: "Order",
  takeout: "Takeout",
  cook: "Cook",
};
