import { Icon } from "@iconify/react";
import { ChartsAxisContentProps } from "@mui/x-charts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSwipeable } from "react-swipeable";
import Chart from "../../../components/Chart";
import Headline, { SubHeadline } from "../../../components/Headline";
import { getIcon } from "../../../data/icons";
import { createSeries, generateExpenses } from "../../../functions/general";
import { formattedDateCustom } from "../../../helper/dateHelper";
import { useStatisticsContext } from "../statistics/Statistics";
import { LocalChartSeriesType, StatisticsContextType } from "../statistics/types";
import { billsTags, entertainmentTags, householdTags, miscTags, transportTags, wellnessTags } from "../types";

const ChartExpensesByTags = () => {
  const { expenses } = useStatisticsContext() as StatisticsContextType;

  const [date, setDate] = useState<Date>(new Date());

  const [daysOfMonth, setDaysOfMonth] = useState<number[]>([]);

  const [expensesByCategory, setExpensesByCategory] = useState({
    total: [] as (number | null)[],
    household: [] as (number | null)[],
    bills: [] as (number | null)[],
    entertainment: [] as (number | null)[],
    transport: [] as (number | null)[],
    wellness: [] as (number | null)[],
    misc: [] as (number | null)[],
  });

  const handleMonth = (direction: "back" | "forward") => {
    setDate((prev) => {
      const d = new Date(prev);
      return direction === "back" ? new Date(d.setMonth(prev.getMonth() - 1)) : new Date(d.setMonth(prev.getMonth() + 1));
    });
  };

  const getTagExpenses = useCallback((tags: string[]) => expenses.filter((e) => e.tags?.some((t) => tags.includes(t))), [expenses]);

  useEffect(() => {
    const { daysOfMonth, expensesOfMonthWithNull: total } = generateExpenses(expenses, date);
    const categories = {
      household: generateExpenses(getTagExpenses(householdTags), date).expensesOfMonthWithNull,
      bills: generateExpenses(getTagExpenses(billsTags), date).expensesOfMonthWithNull,
      entertainment: generateExpenses(getTagExpenses(entertainmentTags), date).expensesOfMonthWithNull,
      transport: generateExpenses(getTagExpenses(transportTags), date).expensesOfMonthWithNull,
      wellness: generateExpenses(getTagExpenses(wellnessTags), date).expensesOfMonthWithNull,
      misc: generateExpenses(getTagExpenses(miscTags), date).expensesOfMonthWithNull,
    };

    setDaysOfMonth(daysOfMonth);
    setExpensesByCategory({ total, ...categories });
    // eslint-disable-next-line
  }, [expenses, date, getTagExpenses]);

  const CustomTooltip = ({ series, dataIndex, axisData }: ChartsAxisContentProps) => {
    if (!dataIndex) return null;
    const data = series.filter((s) => s.data[dataIndex] !== null).map((s) => ({ data: s.data[dataIndex], series: s }));

    if (!data.length) return null;

    const formattedDate = axisData.x?.index
      ? formattedDateCustom(new Date(date).setDate(axisData.x.index + 1), {
          day: "numeric",
          month: "long",
        })
      : null;

    return (
      <div className="bg-surface-top p-2 rounded-sm">
        {axisData.x?.index && <p className="pb-2 mb-2 border-b border-border">{formattedDate}</p>}
        {data.map((d) => {
          if (!d) return null;
          const { data, series } = d;
          const { color, label } = series as LocalChartSeriesType;
          return (
            <div key={series.id} className="flex gap-2 items-center text-sm">
              <div className={`w-2 h-2 rounded-full`} style={{ backgroundColor: color }} />
              <p className="text-text-secondary">{label}</p>
              <div className="flex-1 h-px border-b border-border border-dotted" />
              <p className="">{data?.toString()}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: (e) => {
      e.event.stopPropagation();
      handleMonth("back");
    },
    onSwipedRight: (e) => {
      e.event.stopPropagation();
      handleMonth("forward");
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const dateString = useMemo(() => {
    const month = formattedDateCustom(date, { month: "long" }).split(" ");
    const year = formattedDateCustom(date, { year: "numeric" }).split(" ");
    return { month, year };
  }, [date]);

  return (
    <>
      <div className="flex items-start justify-center gap-8">
        <button onClick={() => handleMonth("back")} className="opacity-80 hover:opacity-100 transition-opacity duration-300 p-1">
          <Icon icon={getIcon("chevron-left")} />
        </button>
        <button
          className="text-center flex flex-col items-center justify-baseline flex-1"
          disabled={date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear()}
          onClick={() => setDate(new Date())}
        >
          <Headline>{dateString.month}</Headline>
          <SubHeadline>{dateString.year}</SubHeadline>
        </button>
        <button onClick={() => handleMonth("forward")} className="opacity-80 hover:opacity-100 transition-opacity duration-300 p-1">
          <Icon icon={getIcon("chevron-right")} />
        </button>
      </div>
      <div className="w-full aspect-video pointer-events-auto" {...handlers}>
        <Chart
          series={[
            { data: expensesByCategory.total, id: "all-expenses", stack: "all", label: "Total" },
            createSeries(expensesByCategory.household, "Household"),
            createSeries(expensesByCategory.bills, "Bills"),
            createSeries(expensesByCategory.entertainment, "Entertainment"),
            createSeries(expensesByCategory.transport, "Transport"),
            createSeries(expensesByCategory.wellness, "Wellness"),
            createSeries(expensesByCategory.misc, "Misc"),
          ]}
          totalPerDay={expensesByCategory.total}
          xData={daysOfMonth}
          customTooltip={CustomTooltip}
        />
      </div>
    </>
  );
};

export default ChartExpensesByTags;
