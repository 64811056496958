import { Icon } from "@iconify/react";
import debounce from "lodash.debounce";
import { Children, isValidElement, memo, ReactElement, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { getIcon } from "../data/icons";
import { PathRoute } from "../routes/routes";

const NavigationBar = ({ items, addElement }: { items?: PathRoute[]; addElement?: JSX.Element }) => {
  const navRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!navRef || !navRef.current) return;
    const resizeObserver = new ResizeObserver(
      debounce(() => {
        const rootElement = document.getElementById("root");
        if (rootElement && navRef && navRef.current) rootElement.style.paddingBottom = navRef.current?.clientHeight + "px";
      }, 500)
    );
    resizeObserver.observe(navRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const itemsLeft = items?.slice(0, 2);
  const itemsRight = items?.slice(2, 4);

  return (
    <>
      <div ref={navRef} className="fixed bottom-0 left-0 right-0 max-w-screen-sm sm:px-4 sm:py-4 mx-auto flex sm:hidden justify-center z-50">
        <div className="relative grid grid-cols-[1fr_auto_1fr] items-center w-full sm:w-auto bg-surface-top min-h-[60px] pb-4">
          <ItemContainer>
            {itemsLeft?.map((route) => {
              return <Item key={route.path} route={route} />;
            })}
          </ItemContainer>
          {addElement && <div className="add-element -translate-y-1/2 px-4">{addElement}</div>}
          <ItemContainer>
            {itemsRight?.map((route) => (
              <Item key={route.path} route={route} />
            ))}
          </ItemContainer>
        </div>
      </div>
      {addElement && (
        <div className="bottom-4 left-0 right-0 w-min fixed mx-auto hidden sm:flex z-50">
          <div className="add-element">{addElement}</div>
        </div>
      )}
    </>
  );
};

export default memo(NavigationBar);

export const AddButton = ({ onClick, active }: { onClick?: () => void; active: boolean }) => {
  return (
    <button
      className="bg-primary rounded-full p-2 text-xl shadow-[0_0_0_4px] shadow-primary/50 hover:shadow-[0_0_0_0px,0_0_0_4px] hover:shadow-primary/70 transition-shadow duration-300"
      onClick={onClick}
    >
      <Icon icon={getIcon("plus")} className={`transition-transform duration-300 text-primaryContrast ${active ? "rotate-[135deg]" : ""}`} />
    </button>
  );
};

const ItemContainer = ({ children }: { children: ReactElement<ItemType>[] | ReactElement<ItemType> | undefined }) => {
  Children.forEach(children, (child) => {
    if (!isValidElement(child) || child.type !== Item) {
      throw new Error("ItemContainer only accepts Item components as children.");
    }
  });
  return (
    <div
      className="
        flex items-center gap-4 px-4 
        first:pl-8 first:justify-end
        last:pr-8 last:justify-start
      "
    >
      {children}
    </div>
  );
};

interface ItemType {
  route: PathRoute;
}
const Item = ({ route }: ItemType) => {
  const location = useLocation();
  const isActive = location.pathname === route.path;
  return (
    <Link
      to={route.path}
      className={`
        inline-flex items-center justify-center flex-col gap-1 h-full py-2 pt-4 relative
        before:content-[''] before:w-0 before:h-1 before:bg-primary before:absolute before:left-0 before:top-0 before:transition-[width] before:duration-300
        ${isActive ? "before:w-full" : ""}
      `}
    >
      <Icon icon={getIcon(route.icon)} className={`transition-all duration-300 text-2xl`} />
    </Link>
  );
};
