import { Icon } from "@iconify/react";
import debounce from "lodash.debounce";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getIcon } from "../data/icons";
import routes, { containsPath, getRoute, navigationRoutes, PathRoute } from "../routes/routes";

const Header = () => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (!headerRef.current) return;

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        const height = headerRef.current?.clientHeight || 0;
        const rootElement = document.getElementById("root");
        if (rootElement) rootElement.style.paddingTop = `${height}px`;
        setHeaderHeight(height);
      }, 500)
    );
    resizeObserver.observe(headerRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  const currentRoute = useMemo(() => getRoute(pathname), [pathname]);

  return (
    <>
      <header ref={headerRef} className="fixed top-0 left-0 right-0 bg-surface z-10">
        <div className="max-w-screen-sm py-4 px-4 mx-auto flex items-center gap-4 justify-between text-xl">
          <div className="flex items-center gap-2">
            <Link to={routes.index.path}>
              <Icon icon={getIcon("home")} />
            </Link>
            {currentRoute?.text && <h1 className="text-sm font-semibold">{currentRoute.text}</h1>}
          </div>
          <div className="hidden sm:flex items-center gap-4">
            {containsPath(navigationRoutes.finances, pathname) && navigationRoutes.finances.map((route) => <HeaderItem key={route.path} route={route} />)}
          </div>
        </div>
      </header>
      <ToastContainer draggable limit={3} position="top-center" draggablePercent={40} style={{ top: headerHeight }} />
    </>
  );
};

export default Header;

const HeaderItem = ({ route }: { route: PathRoute }) => {
  const { pathname } = useLocation();
  return (
    <Link to={route.path} className={`text-sm transition-all duration-300 ${pathname === route.path ? "text-primary" : "opacity-80 hover:opacity-100"}`}>
      {route.text}
    </Link>
  );
};
