import { collection, onSnapshot, query } from "firebase/firestore"
import { memo, useEffect, useState } from "react"
import { useFlainContext } from "../../App"
import Container from "../../components/Container"
import { FlainContextType } from "../../data/types"
import { db } from "../../firebase/firebase"
import FoodItem from "./FoodItem"
import { Food } from "./types"

const FoodList = () => {

  const { user } = useFlainContext() as FlainContextType

  const [food, setFood] = useState<Food[]>([])

  useEffect(() => {
    if (!user) return

    const q = query(collection(db, "food"))
    const unsub = onSnapshot(q, (querySnapshot) => {
      const tempFood: Food[] = []
      querySnapshot.forEach((doc) => {
        tempFood.push({ ...doc.data() as Food, id: doc.id })
      })
      setFood(tempFood)
    })
    return () => unsub()
  }, [user])
  return (

    <Container background>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
        {food?.map(f => {
          return <FoodItem key={f.id} food={f} />
        })}
      </div>
    </Container>
  )
}

export default memo(FoodList)