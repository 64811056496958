import { updateEmail, updateProfile } from "firebase/auth";
import { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { isEmail } from "validator";
import { useFlainContext } from "../../App";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Input, { InputField } from "../../components/Input";
import { FlainContextType } from "../../data/types";
import { logout } from "../../firebase/firebase";
import { toggleIcon } from "../../functions/general";
import { copy } from "../../helper/stringHelper";
import SettingField from "./SettingsField";
import SettingsSection from "./SettingsSection";

const SettingsProfile = () => {
  const { user } = useFlainContext() as FlainContextType;
  const [editEmail, setEditEmail] = useState(false);
  const [email, setEmail] = useState(user?.email || "");
  const [emailError, setEmailError] = useState("");

  const [editName, setEditName] = useState(false);
  const [name, setName] = useState(user?.displayName || "");
  const [nameError, setNameError] = useState("");

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const resetEmail = () => setEmail(user?.email || "");

  const handleName = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const resetName = () => setName(user?.displayName || "");

  const valid = useMemo(() => {
    const validEmail = isEmail(email) && email !== (user?.email || undefined || "");
    const validName = name !== (user?.displayName || undefined || "");
    return {
      email: validEmail,
      name: validName,
      either: validEmail || validName,
      all: validEmail && validName,
    };
  }, [email, name, user?.email, user?.displayName]);

  const handleProfileInfo = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (user) {
      if (valid.name) {
        updateProfile(user, { displayName: name })
          .then(() => toast(`Updated username: "${user.displayName}"`))
          .catch((error) => setNameError(error));
      }
      if (valid.email) {
        updateEmail(user, email)
          .then(() => toast(`updated email: "${user.email}"`))
          .catch((error) => setEmailError(error));
      }
    }
  };

  if (!user) return null;

  return (
    <>
      <Container background small>
        <SettingsSection title="Personal Info">
          <InputField
            label="Uid"
            input={
              <Input
                disabled
                value={user.uid}
                buttons={{
                  icon: "copy",
                  onClick: (e) => {
                    const button = (e as MouseEvent).currentTarget;
                    copy(user.uid).then(() => {
                      toggleIcon(button);
                    });
                  },
                }}
              />
            }
          />
          <SettingField
            label="Email"
            value={email}
            setValue={setEmail}
            originalValue={user.email}
            edit={editEmail}
            setEdit={setEditEmail}
            handle={handleEmail}
            reset={resetEmail}
            error={emailError}
          />
          <SettingField
            label="Name"
            value={name}
            setValue={setName}
            originalValue={user.displayName}
            edit={editName}
            setEdit={setEditName}
            handle={handleName}
            reset={resetName}
            error={nameError}
          />
          <Button type="submit" onClick={handleProfileInfo} disabled={!valid.either} icon="send">
            Submit
          </Button>
        </SettingsSection>
      </Container>
      <Container small background>
        <Button onClick={logout} icon="signOut" className="w-full">
          Sign out
        </Button>
      </Container>
    </>
  );
};

export default SettingsProfile;
