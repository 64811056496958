import React, { Dispatch, memo, ReactElement, ReactNode } from "react";
import { createPortal } from "react-dom";

const Popover = ({
  id,
  children,
  isOpen,
  setIsOpen,
  trigger,
}: {
  id: string;
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
  trigger: ReactElement;
}) => {
  return (
    <>
      <div key={id} className={`popover inline-flex justify-center z-50`}>
        {React.cloneElement(trigger, { onClick: () => setIsOpen(!isOpen) })}
        {isOpen && (
          <>
            {createPortal(<div className="popover-backdrop fixed left-0 top-0 right-0 bottom-0 w-full h-full z-10" onClick={() => setIsOpen(false)} />, document.body)}
            <div
              className={`
                popover-wrapper absolute bottom-0 m-0 z-50 -translate-y-14 w-screen sm:max-w-96 px-8
                after:content-[""] after:w-0 after:h-0 after:mx-auto
                after:border-8 after:border-solid after:border-transparent after:border-t-surface-top
                after:absolute after:bottom-0 after:left-0 after:right-0 after:translate-y-full
              `}
            >
              <div className="rounded-lg bg-surface-top w-full">
                <div className="w-full h-full max-h-96 sm:max-h-[512px] overflow-y-scroll scrollbar-thin relative">
                  <div className="overflow-hidden">{children}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default memo(Popover);
