import { Icon } from "@iconify/react";
import {
  BarPlot,
  ChartsXAxis,
  ChartsXAxisProps,
  ChartsYAxis,
  ChartsYAxisProps,
  lineElementClasses,
  LinePlot,
  ResponsiveChartContainer,
  ResponsiveChartContainerProps,
} from "@mui/x-charts";
import { collection, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFlainContext } from "../../../App";
import Container from "../../../components/Container";
import { SubHeadline } from "../../../components/Headline";
import { getIcon } from "../../../data/icons";
import { FlainContextType } from "../../../data/types";
import { db } from "../../../firebase/firebase";
import { generateExpenses } from "../../../functions/general";
import { formattedDateCustom, numberToDate } from "../../../helper/dateHelper";
import { currency } from "../../../helper/stringHelper";
import { colors } from "../../finances/statistics/types";
import { ExpenseInterface } from "../../finances/types";

const ChartExpenses = ({ period, type }: { period?: "Month" | "Year"; type?: "Cumulative" | "Daily" }) => {
  const { user } = useFlainContext() as FlainContextType;

  const [state, setState] = useState({
    dbExpenses: [] as ExpenseInterface[],
    useType: type || "Cumulative",
    usePeriod: period || "Month",
    barType: "line" as "line" | "bar",
    loading: true,
  });

  useEffect(() => {
    if (!user) return;

    const unsub = onSnapshot(collection(db, "expenses"), (querySnapshot) => {
      const tempExpenses = querySnapshot.docs.map((doc) => doc.data() as ExpenseInterface);
      setState((prevState) => ({ ...prevState, dbExpenses: tempExpenses, loading: false }));
    });

    return () => unsub();
  }, [user]);

  const { expenses, days, totalExpenses } = useMemo(() => {
    const { dbExpenses, usePeriod, useType } = state;
    if (dbExpenses.length === 0) return { expenses: [] as number[], days: [] as number[], totalExpenses: 0 };

    const { expensesOfMonthByDay, expensesOfYearByDay, cumulativeExpensesOfMonth, cumulativeExpensesOfYear, daysOfMonth, daysOfYear } = generateExpenses(
      dbExpenses,
      new Date()
    );

    const expenses =
      usePeriod === "Month"
        ? useType === "Cumulative"
          ? cumulativeExpensesOfMonth
          : expensesOfMonthByDay
        : useType === "Cumulative"
        ? cumulativeExpensesOfYear
        : expensesOfYearByDay;

    const days = usePeriod === "Month" ? daysOfMonth : daysOfYear;
    const totalExpenses = useType === "Cumulative" ? expenses[expenses.length - 1] : expenses.reduce((a, b) => a + b, 0);

    return { expenses, days, totalExpenses };
    // eslint-disable-next-line
  }, [state.dbExpenses, state.usePeriod, state.useType]);

  const togglePeriod = useCallback(() => {
    setState((prev) => ({ ...prev, usePeriod: prev.usePeriod === "Month" ? "Year" : "Month" }));
  }, []);

  const toggleType = useCallback(() => {
    setState((prev) => ({
      ...prev,
      useType: prev.useType === "Cumulative" ? "Daily" : "Cumulative",
      barType: prev.barType === "line" ? "bar" : "line",
    }));
  }, []);

  const date = useMemo(() => {
    const d = new Date();
    return state.usePeriod === "Month" ? formattedDateCustom(d, { month: "long" }) : formattedDateCustom(d, { year: "numeric" });
  }, [state.usePeriod]);

  const options: ResponsiveChartContainerProps = {
    series: [{ data: expenses, type: state.barType, curve: "linear" }],
    xAxis: [
      {
        scaleType: state.barType === "bar" ? "band" : "linear",
        data: days,
        disableTicks: true,
        valueFormatter: (v: number) => formattedDateCustom(numberToDate(v), { day: "numeric", month: "long" }).split(" ").join(". "),
      },
    ],
    yAxis: [{ scaleType: "sqrt" }],
    margin: { left: 0, bottom: 5, top: 5, right: 0 },
    height: 100,
    colors: colors,
    sx: {
      [`.${lineElementClasses.root}`]: {
        stroke: "rgb(var(--primary))",
        strokeWidth: 1.5,
      },
    },
  };

  const axisProps: Partial<ChartsXAxisProps & ChartsYAxisProps> = {
    disableTicks: true,
    disableLine: true,
  };

  if (state.loading) {
    return (
      <Container background className="relative">
        <div className="absolute left-0 top-0 right-0 bottom-0 w-full h-full bg-surface/95 flex items-center justify-center">
          <Icon icon={getIcon("loading")} />
        </div>
      </Container>
    );
  }

  return (
    <Container background className="relative">
      <div className="flex items-baseline justify-between">
        <div className="flex flex-col">
          <button className="text-sm text-left inline-flex items-baseline gap-1" onClick={togglePeriod} disabled={Boolean(period)}>
            <span>Current {state.usePeriod}</span>
          </button>
          <SubHeadline>{date}</SubHeadline>
        </div>
        <div className="flex flex-col justify-start items-end">
          <div className="flex items-baseline gap-0.5">
            <span className="text-xs text-text-secondary">RM</span>
            {expenses.length > 0 && <span className="text-lg">{currency(totalExpenses)}</span>}
          </div>
          <button onClick={toggleType} disabled={Boolean(type)}>
            <SubHeadline>{state.useType}</SubHeadline>
          </button>
        </div>
      </div>
      <ResponsiveChartContainer {...options}>
        <LinePlot />
        <BarPlot />
        <ChartsXAxis {...axisProps} />
        <ChartsYAxis {...axisProps} />
      </ResponsiveChartContainer>
    </Container>
  );
};

export default ChartExpenses;
