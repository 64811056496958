import { memo } from "react";
import { currency } from "../../helper/stringHelper";

const Sum = ({ week, month }: { week: number; month: number }) => (
  <div className="bg-primary bg-noise p-2 sm:p-4 rounded-sm text-sm flex flex-col sm:flex-row justify-between">
    <SumItem side="left" amount={week} />
    <hr className="sm:hidden my-1 bg-primaryContrast/20" />
    <SumItem side="right" amount={month} />
  </div>
);

export default memo(Sum);

const SumItem = ({ amount, side = "left" }: { amount: number; side: "left" | "right" }) => (
  <div className={`flex flex-col gap-0 text-primaryContrast ${side === "left" ? "text-left" : "text-right"}`}>
    <div className="">
      <span className="font-light opacity-80">RM</span>
      <span className="text-3xl sm:text-5xl font-extrabold ml-0.5">{currency(amount)}</span>
    </div>
    <div className={`text-xl sm:text-2xl font-light opacity-70`}>{side === "left" ? "Week" : "Month"}</div>
  </div>
);
