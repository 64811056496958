import { Icon } from "@iconify/react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { getIcon, IconType } from "../data/icons";

const ContextMenu = ({ items }: { items: MenuItemProps[] }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const menuButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const closeClick = (e: MouseEvent) => {
      if (!(e.target && menuButtonRef && menuButtonRef.current)) return;
      const outsideClick = !menuButtonRef.current.contains(e.target as Node);
      if (outsideClick) setMenuOpen(false);
    };
    document.addEventListener("click", closeClick);
    return () => document.removeEventListener("click", closeClick);
  }, []);

  return (
    <span className="relative inline-flex items-center justify-center">
      <button
        ref={menuButtonRef}
        onClick={() => setMenuOpen(!menuOpen)}
        className={`transition-colors duration-300 relative ${menuOpen ? "opacity-100" : "opacity-50"}`}
      >
        <Icon icon={getIcon("ellipsis-vertical")} />
      </button>
      {menuOpen && (
        <div className="absolute right-0 bottom-0 translate-y-full bg-surface-top rounded-sm text-sm z-10">
          {items.map((item, i) => (
            <MenuItem key={i} {...item} />
          ))}
        </div>
      )}
    </span>
  );
};

export default ContextMenu;

interface MenuItemProps {
  children: ReactNode;
  icon: IconType;
  onClick: () => void;
  danger?: boolean;
}

export const MenuItem = ({ icon, onClick, danger, children }: MenuItemProps) => {
  return (
    <button onClick={onClick} className={`flex items-center gap-2 py-1 px-2 ${danger ? "text-red-400" : ""}`}>
      <Icon icon={getIcon(icon)} /> {children}
    </button>
  );
};
