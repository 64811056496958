import { Icon } from "@iconify/react";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFlainContext } from "../../../App";
import Container from "../../../components/Container";
import Headline from "../../../components/Headline";
import { getIcon } from "../../../data/icons";
import { FlainContextType } from "../../../data/types";
import { db } from "../../../firebase/firebase";
import { Reminder } from "../../reminders/types";
import routes from "../../routes";

const UpcomingReminders = () => {
  const { user } = useFlainContext() as FlainContextType;
  const [reminders, setReminders] = useState<Reminder[]>([]);

  const [remindersWeekly, setRemindersWeekly] = useState<Reminder[]>([]);
  const [remindersOnce, setRemindersOnce] = useState<Reminder[]>([]);
  const [remindersDaily, setRemindersDaily] = useState<Reminder[]>([]);

  const dateNow = new Date();
  const currentTimeString = dateNow.toTimeString().flainGetTimeString();
  const twoHoursLater = new Date(dateNow);
  twoHoursLater.setHours(dateNow.getHours() + 2);
  const twoHoursLaterString = twoHoursLater.toTimeString().flainGetTimeString();
  const start = new Date(dateNow);
  start.setHours(0, 0, 0, 0);
  const startString = start.toTimeString().flainGetTimeString();
  const end = new Date(dateNow);
  end.setHours(23, 59, 59, 59);
  const endString = end.toTimeString().flainGetTimeString();

  useEffect(() => {
    if (!user) return;

    const day = dateNow.getDay();
    const dayOfMonth = dateNow.getDate();
    const month = dateNow.getMonth();
    const year = dateNow.getFullYear();

    const queryBase = query(
      collection(db, "reminders"),
      where("subscribers", "array-contains", user?.uid),
      where("time", ">=", startString),
      where("time", "<=", endString),
      orderBy("time", "desc")
    );

    const queryWeekly = query(queryBase, where("frequency", "==", "Weekly"), where("date.day", "==", day));
    const queryOnce = query(
      queryBase,
      where("frequency", "==", "Once"),
      where("date.day", "==", day),
      where("date.dayOfMonth", "==", dayOfMonth),
      where("date.month", "==", month),
      where("date.year", "==", year)
    );
    const queryDaily = query(queryBase, where("frequency", "==", "Daily"));

    const unsubDaily = onSnapshot(queryDaily, (snapshot) => {
      const newReminders = snapshot.docs.map((doc) => ({ ...(doc.data() as Reminder), id: doc.id }));
      setRemindersDaily(newReminders);
    });

    const unsubOnce = onSnapshot(queryOnce, (snapshot) => {
      const newReminders = snapshot.docs.map((doc) => ({ ...(doc.data() as Reminder), id: doc.id }));
      setRemindersOnce(newReminders);
    });

    const unsubWeekly = onSnapshot(queryWeekly, (snapshot) => {
      const newReminders = snapshot.docs.map((doc) => ({ ...(doc.data() as Reminder), id: doc.id }));
      setRemindersWeekly(newReminders);
    });

    return () => {
      unsubDaily();
      unsubOnce();
      unsubWeekly();
    };
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    setReminders([...remindersDaily, ...remindersWeekly, ...remindersOnce]);
  }, [remindersDaily, remindersWeekly, remindersOnce]);

  return (
    <Container background>
      <Headline className="mb-1">Today&apos;s reminders</Headline>
      {reminders?.length ? (
        <div className="flex flex-col gap-1 relative">
          {reminders.map(({ title, done, time, id }) => {
            const inNextTwoHours = time >= currentTimeString && time <= twoHoursLaterString;
            return (
              <Link
                to={`${routes.index.children.reminders.path}#${id}`}
                key={id}
                className={`
                  text-sm py-2 px-4 bg-surface-top flex items-center justify-between 
                  ${done ? "opacity-40 order-2" : "order-1"} 
                  ${inNextTwoHours ? "" : "opacity-70"}
                `}
              >
                <span className="inline-flex gap-2 items-center">
                  {title}
                  {done && <Icon icon={getIcon("check")} />}
                </span>
                <span className="text-xs">{time}</span>
              </Link>
            );
          })}
        </div>
      ) : (
        <p className="text-sm text-text-secondary p-4 text-center">Nothing coming up</p>
      )}
    </Container>
  );
};

export default UpcomingReminders;
