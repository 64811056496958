import { Icon } from "@iconify/react";
import { TextareaAutosize } from "@mui/material";
import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, Timestamp, updateDoc } from "firebase/firestore";
import Markdown from "markdown-to-jsx";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFlainContext } from "../../../App";
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import ContextMenu from "../../../components/ContextMenu";
import Headline from "../../../components/Headline";
import Input, { InputField } from "../../../components/Input";
import { getIcon } from "../../../data/icons";
import { FlainContextType } from "../../../data/types";
import { db } from "../../../firebase/firebase";
import { formattedDateCustom } from "../../../helper/dateHelper";

export interface Update {
  id: string;
  title: string;
  description: string;
  timestamp: Timestamp;
}

const Updates = () => {
  const { user, userData } = useFlainContext() as FlainContextType;
  const [updates, setUpdates] = useState<Update[]>([]);
  const newUpdateDefault: Update = {
    id: "",
    title: "",
    description: "",
    timestamp: Timestamp.now(),
  };
  const [newUpdate, setNewUpdate] = useState<Update>(newUpdateDefault);
  const [newUpdateOpen, setNewUpdateOpen] = useState(false);

  const valid = useMemo(() => {
    const titleValid = Boolean(newUpdate.title.trim());
    const descriptionValid = Boolean(newUpdate.description.trim());
    return { title: titleValid, description: descriptionValid, all: titleValid && descriptionValid };
  }, [newUpdate.title, newUpdate.description]);

  useEffect(() => {
    if (!user) return;
    const unsub = onSnapshot(query(collection(db, "updates"), orderBy("timestamp", "desc")), (querySnapshot) => {
      const tempUpdates: Update[] = [];
      querySnapshot.forEach((doc) => {
        tempUpdates.push({ ...(doc.data() as Update), id: doc.id });
      });
      setUpdates(tempUpdates);
    });
    return () => unsub();
  }, [user]);

  const handleNewUpdate = async (e: FormEvent) => {
    e.preventDefault();
    if (!valid.all) return;
    await addDoc(collection(db, "updates"), { ...newUpdate, id: undefined, timestmap: Timestamp.now() })
      .then(() => {
        setNewUpdate(newUpdateDefault);
        toast("Sent new update");
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      {userData?.isAdmin && (
        <>
          <div className={`transition-[max-height,opacity] duration-500 ${newUpdateOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"}`}>
            <Container background>
              <Headline className="mb-1">New Update</Headline>
              <div className="flex flex-col items-center gap-2">
                <Input label="Title" value={newUpdate.title} onChange={(e) => setNewUpdate((prev) => ({ ...prev, title: e.target.value.trim() }))} />
                <InputField
                  label="Description"
                  input={
                    <TextareaAutosize
                      value={newUpdate.description}
                      onChange={(e) => {
                        setNewUpdate((prev) => ({ ...prev, description: e.target.value }));
                      }}
                    />
                  }
                />
                <Button onClick={handleNewUpdate} small icon="update-text" disabled={!valid.all || !userData.isAdmin}>
                  Send Update
                </Button>
              </div>
            </Container>
          </div>
          <div className="flex items-center justify-center -mb-7 z-10 relative">
            <button onClick={() => setNewUpdateOpen(!newUpdateOpen)} className="border-4 border-surface rounded-full text-xl text-primary">
              <Icon icon={getIcon("plus-circle")} className={`transition-transform duration-500 ${newUpdateOpen ? "rotate-45" : ""}`} />
            </button>
          </div>
        </>
      )}
      <Container background>
        <Headline className="mb-1">Updates</Headline>
        <div className="flex flex-col gap-1">
          {(newUpdate.title || newUpdate.description) && <UpdateItem update={newUpdate} preview />}
          {updates?.length ? (
            updates?.map((update) => <UpdateItem key={update.id} update={update} />)
          ) : (
            <p className="text-sm text-text-secondary p-4 text-center">No Updates</p>
          )}
        </div>
      </Container>
    </>
  );
};

export default Updates;

const UpdateItem = ({ update, preview }: { update: Update; preview?: boolean }) => {
  const { user, userData } = useFlainContext() as FlainContextType;
  const { id, title, timestamp, description } = update;
  interface Edit {
    active: boolean;
    update: Update;
  }
  const defaultEdit = {
    active: false,
    update: {
      id: id,
      title: title,
      description: description,
      timestamp: timestamp,
    },
  };
  const [edit, setEdit] = useState<Edit>(defaultEdit);

  const deleteUpdate = async () => {
    if (!user && !preview) return;
    const ref = doc(db, "updates", id);
    await deleteDoc(ref)
      .then(() => toast(`Deleted Update "${title}"`))
      .catch((error) => console.error(error));
  };

  const editUpdate = async () => {
    if (!user || !hasEdit || preview) return;
    const ref = doc(db, "updates", id);
    await updateDoc(ref, {
      title: edit.update.title.trim(),
      description: edit.update.description.trim(),
    }).catch((error) => console.error(error));
  };

  const resetUpdate = async () => {
    setEdit({ ...defaultEdit, active: edit.active });
  };

  const editClasses = "bg-transparent border-none p-0 text-blue-200 focus:ring-0";
  const buttonClasses = "disabled:text-text-secondary";

  const hasEdit = useMemo(() => {
    return title !== edit.update.title.trim() || description !== edit.update.description.trim();
    // eslint-disable-next-line
  }, [edit.update.title, edit.update.description]);

  return (
    <>
      <div className="w-full bg-surface rounded-sm flex flex-col gap-1 p-4 relative">
        {preview && <p className="text-xs text-text-secondary">Preview</p>}
        <div className="flex items-start justify-between gap-4">
          {edit.active ? (
            <input
              type="text"
              value={edit.update.title}
              className={`font-semibold text-sm ${editClasses}`}
              onChange={(e) => setEdit((prev) => ({ ...prev, update: { ...update, title: e.target.value } }))}
            />
          ) : (
            <h5 className="text-text-secondary text-sm font-semibold">{title}</h5>
          )}
          <div className="flex gap-2 items-center">
            <span className="text-xs inline-flex items-center gap-2">
              <Icon icon={getIcon("calendar")} fontSize={16} className="text-text-secondary" />
              {formattedDateCustom(new Date(timestamp.seconds * 1000), {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })}
            </span>

            <ContextMenu
              items={[
                {
                  onClick: () => setEdit((prev) => ({ ...prev, active: !prev.active })),
                  icon: edit.active ? "x" : "edit",
                  children: edit.active ? "Cancel" : "Edit",
                },
                { children: "Delete", icon: "trash", onClick: deleteUpdate, danger: true },
              ]}
            />
          </div>
        </div>
        {edit.active ? (
          <TextareaAutosize
            value={edit.update.description}
            className={`max-w-full text-sm w-full ${editClasses}`}
            onChange={(e) => setEdit((prev) => ({ ...prev, update: { ...update, description: e.target.value } }))}
          />
        ) : (
          <Markdown
            className="markdown text-sm"
            options={{
              overrides: {
                a: ({ href, children }) => (
                  <Link to={href} className="text-primary underline">
                    {children}
                  </Link>
                ),
              },
            }}
          >
            {description}
          </Markdown>
        )}
        {userData?.isAdmin && !preview && edit.active && (
          <>
            <hr className="my-1" />
            <div className="flex justify-end gap-4">
              {edit.active && hasEdit && (
                <button disabled={!userData.isAdmin || !hasEdit} onClick={resetUpdate} className={buttonClasses}>
                  <Icon icon={getIcon("reset")} />
                </button>
              )}
              {hasEdit && edit.active && (
                <button disabled={!userData.isAdmin} onClick={editUpdate} className={buttonClasses}>
                  <Icon icon={getIcon("upload")} />
                </button>
              )}
              <button disabled={!userData.isAdmin} onClick={() => setEdit((prev) => ({ ...prev, active: !prev.active }))} className={buttonClasses}>
                <Icon icon={getIcon(edit.active ? "x" : "edit")} />
              </button>
            </div>
          </>
        )}
      </div>
      {edit.active && hasEdit && <UpdateItem update={edit.update} preview />}
    </>
  );
};
