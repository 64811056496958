import { axisClasses, BarChart, BarSeriesType, ChartsAxisContentProps, chartsAxisHighlightClasses } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/internals";
import { ElementType } from "react";
import { colors } from "../routes/finances/statistics/types";

const Chart = ({
  series,
  totalPerDay,
  xData,
  customTooltip,
}: {
  series: MakeOptional<BarSeriesType, "type">[];
  totalPerDay: (number | null)[];
  xData: number[];
  customTooltip?: ElementType<ChartsAxisContentProps>;
}) => {
  const sanitizedExpenses = totalPerDay.map((e) => e ?? 0);
  const maxExpense = Math.max(...sanitizedExpenses);
  const minExpense = Math.min(...sanitizedExpenses);

  return (
    <BarChart
      colors={colors}
      series={series}
      xAxis={[
        {
          scaleType: "band",
          data: xData,
          tickInterval: (index, i) => index % 5 === 0 || (totalPerDay[i] ? totalPerDay[i]! > 0 : false),
          hideTooltip: true,
        },
      ]}
      yAxis={[
        {
          max: maxExpense === 0 ? 1 : maxExpense,
          min: minExpense,
          tickMaxStep: (totalPerDay.length * 100) / 4,
          tickMinStep: 1,
          scaleType: "sqrt",
        },
      ]}
      axisHighlight={{ x: "none" }}
      tooltip={{ trigger: "axis" }}
      slots={{
        axisContent: customTooltip,
      }}
      slotProps={{
        legend: {
          direction: "row",
          itemMarkWidth: 5,
          itemMarkHeight: 5,
          markGap: 5,
          itemGap: 10,
          //onItemClick: (event, context, index) => setItemData([context, index]),
          labelStyle: {
            fontSize: 12,
            fill: "var(--text)",
          },
          position: {
            horizontal: "middle",
            vertical: "top",
          },
        },
      }}
      leftAxis={{
        disableTicks: true,
        disableLine: true,
        tickLabelStyle: {
          angle: 45,
          fontSize: 10,
          fill: "var(--text)",
        },
      }}
      bottomAxis={{
        disableTicks: true,
        tickLabelStyle: {},
      }}
      margin={{ left: 30, bottom: 20, top: 60, right: 0 }}
      className="h-full w-full pointer-events-auto select-none"
      sx={{
        [`.${axisClasses.root}`]: {
          [`.${axisClasses.tick}, .${axisClasses.line}`]: {
            stroke: "rgb(var(--border))",
            strokeWidth: 1,
          },
          [`.${axisClasses.tickLabel}`]: {
            fill: "var(--text)",
            fontSize: 10,
          },
          [`.${chartsAxisHighlightClasses.root}`]: {
            fill: "var(--text)",
            stroke: "var(--text)",
            fontSize: 10,
          },
        },
      }}
    />
  );
};

export default Chart;
