import { ReactNode } from "react";
import Headline from "../../components/Headline";

const SettingsSection = ({ children, title }: { children: ReactNode; title: string }) => {
  return (
    <>
      <div className="flex flex-col items-center justify-start gap-2 w-full">
        <Headline>{title}</Headline>
        {children}
      </div>
    </>
  );
};

export default SettingsSection;
