import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useFlainContext } from "../../App";
import Container from "../../components/Container";
import { getIcon } from "../../data/icons";
import { FlainContextType } from "../../data/types";
import { handleNotificationPermission } from "../../firebase/firebase";
import SettingsSection from "./SettingsSection";

const SettingsNotifications = () => {
  const { user } = useFlainContext() as FlainContextType;
  const [notificationsAllowed, setNotificationsAllowed] = useState(Notification.permission === "granted");
  const [notificationPermissionPending, setNotificationPermissionPending] = useState(false);

  useEffect(() => {
    if (!user) return;
  }, [user]);

  return (
    <Container background small>
      <SettingsSection title="Allow Notifications">
        <button
          className="flex items-center gap-2 justify-between"
          disabled={notificationPermissionPending || notificationsAllowed}
          onClick={async () => {
            if (notificationPermissionPending || notificationsAllowed) return;
            setNotificationPermissionPending(true);
            await handleNotificationPermission().then((permission) => setNotificationsAllowed(permission === "granted"));
            setNotificationPermissionPending(false);
          }}
        >
          Notification Permissions
          <Icon icon={getIcon(notificationPermissionPending ? "loading" : notificationsAllowed ? "notification" : "notification-off")} />
        </button>
      </SettingsSection>
    </Container>
  );
};

export default SettingsNotifications;
