import { Icon } from "@iconify/react";
import { arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useFlainContext } from "../../App";
import ContextMenu from "../../components/ContextMenu";
import { getIcon } from "../../data/icons";
import { FlainContextType } from "../../data/types";
import { db } from "../../firebase/firebase";
import { formattedDateCustom, getDayOfWeekFromNumber } from "../../helper/dateHelper";
import { Reminder } from "./types";

const ReminderItem = ({ reminder }: { reminder: Reminder }) => {
  const { user } = useFlainContext() as FlainContextType;
  const { id, title, frequency, time, done, subscribers, date, nextOccurance } = reminder;
  const [isLoading, setIsLoading] = useState(false);
  const defaultReminder = { ...reminder };
  const [edit, setEdit] = useState<{ active: boolean; reminder: Reminder }>({ active: false, reminder: defaultReminder });

  const active = useMemo(() => user?.uid && subscribers.includes(user?.uid), [user?.uid, subscribers]);

  const reminderRef = useMemo(() => doc(db, "reminders", id), [id]);

  const subscribe = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    await updateDoc(reminderRef, {
      subscribers: arrayUnion(user?.uid),
    }).then(() =>
      toast(
        <>
          Subscribed to <span className="text-primary">{title}</span>
        </>
      )
    );
    setIsLoading(false);
  }, [isLoading, reminderRef, user?.uid, title]);

  const unsubscribe = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    await updateDoc(reminderRef, {
      subscribers: arrayRemove(user?.uid),
    }).then(() =>
      toast(
        <>
          Unsubscribed from <span className="text-primary">{title}</span>
        </>
      )
    );
    setIsLoading(false);
  }, [isLoading, reminderRef, user?.uid, title]);

  const handleSubscription = useCallback(async () => {
    if (isLoading) return;
    if (active) await unsubscribe();
    else await subscribe();
  }, [isLoading, active, subscribe, unsubscribe]);

  const deleteReminder = useCallback(async () => {
    await deleteDoc(reminderRef)
      .then(() =>
        toast(
          <>
            Deleted <span className="text-primary">{title}</span>
          </>
        )
      )
      .catch((error) => console.error(error.message));
  }, [reminderRef, title]);

  const toggleDone = useCallback(async () => {
    await updateDoc(reminderRef, {
      done: !done,
    })
      .then(() => toast(`Set "${title}" to ${done ? "undone" : "done"}`))
      .catch((error) => console.error(error));
  }, [reminderRef, done, title]);

  const now = useMemo(() => new Date(), []);

  const todayWithTime = useMemo(() => new Date(new Date(now).setHours(Number(time.split(":")[0]), Number(time.split(":")[1]), 0, 0)), [now, time]);

  const inPast = useMemo(() => {
    if (frequency === "Once") {
      return new Date(date.timestamp.seconds * 1000) < now;
    } else if (frequency === "Daily" || frequency === "Weekly") {
      return todayWithTime < now;
    }
    return false;
  }, [frequency, date, now, todayWithTime]);

  return (
    <div
      id={id}
      className={`
        w-full px-2 py-2 bg-surface rounded hover:shadow-[0_0_0_1px_rgb(var(--surface))] transition-shadow duration-300 
        ${inPast || done ? "opacity-50" : ""}
      `}
    >
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-start gap-1">
          <div className="text-xs text-text-secondary flex items-center gap-4">
            <div className="flex items-center gap-1">
              <Icon icon={getIcon("clock")} fontSize={16} />
              {time}
            </div>

            <div className="flex items-center gap-1 whitespace-nowrap">
              <Icon icon={getIcon("calendar")} fontSize={16} />
              {frequency}
              {frequency === "Weekly" && date && ` on ${getDayOfWeekFromNumber(date?.day)}`}
              {frequency === "Once" && date && ` on ${formattedDateCustom(nextOccurance, { day: "numeric", month: "long", year: "numeric" })}`}
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <button onClick={toggleDone} className={`transition-colors duration-300 ${done ? "text-primary" : ""}`}>
              <Icon icon={getIcon("check")} />
            </button>
            <button onClick={handleSubscription} className={`transition-colors duration-300 ${active ? "text-primary" : ""}`}>
              <Icon icon={getIcon(isLoading ? "loading" : active ? "notification" : "notification-off")} />
            </button>
            <ContextMenu items={[{ children: "Delete", icon: "trash", onClick: deleteReminder, danger: true }]} />
          </div>
        </div>
        {edit.active ? (
          <input
            type="text"
            className="bg-transparent border-none p-0 focus:ring-0"
            value={edit.reminder.title}
            onChange={(e) => setEdit((prev) => ({ ...prev, reminder: { ...reminder, title: e.target.value } }))}
          />
        ) : (
          <p>{title}</p>
        )}
      </div>
    </div>
  );
};

export default ReminderItem;
