import { Icon } from "@iconify/react";
import {
  cloneElement,
  ComponentPropsWithoutRef,
  forwardRef,
  Fragment,
  memo,
  MouseEvent,
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { getIcon, IconType } from "../data/icons";
import ConditionalWrapper from "./ConditionalWrapper";

interface InputProps extends ComponentPropsWithoutRef<"input"> {
  dataList?: { list: string[]; parentRef?: RefObject<HTMLDivElement>; onClick: (value: string) => void };
  buttons?: InputButtonType | InputButtonType[];
  keyEvent?: (s: string | number | readonly string[]) => void;
  label?: string;
  error?: string;
  icon?: IconType;
  small?: boolean;
}
export interface InputButtonType {
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  icon: IconType;
  disabled?: boolean;
  isLoading?: boolean;
  hidden?: boolean;
}

const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(({ dataList, buttons, label, error, children, icon, small, ...props }: InputProps, ref) => {
    const { id, value, type, className } = props;
    const [dataListPosition, setDataListPosition] = useState<"bottom" | "top">("bottom");
    const hintRef = useRef<HTMLDivElement>(null);
    const [inputFocus, setInputFocus] = useState(false);
    const [dataListHeight /* setDataListHeight */] = useState(200);
    const [clickingDataListOption, setClickingDataListOption] = useState(false);

    useEffect(() => {
      if (!dataList) return;
      const adjustHintPosition = () => {
        const hint = hintRef.current;
        const parent = dataList.parentRef?.current;

        if (hint && parent) {
          const hintRect = hint.getBoundingClientRect();
          const parentRect = parent.getBoundingClientRect();
          const spaceBelow = parentRect.bottom - hintRect.bottom;

          setDataListPosition(spaceBelow > hintRect.height ? "bottom" : "top");
        }
      };

      adjustHintPosition();
      window.addEventListener("resize", adjustHintPosition);

      return () => {
        window.removeEventListener("resize", adjustHintPosition);
      };
    }, [value, dataList?.parentRef, inputFocus, dataListHeight, dataList]);

    return (
      <label key={`input-container-${id}`} className="input-label flex flex-col gap-1 w-full">
        {label && <span className="text-xs text-gray-400 pl-2">{label}</span>}
        <ConditionalWrapper
          condition={Boolean(dataList)}
          wrapper={(children) => {
            const filteredDataList = dataList?.list.filter((option) => option.toLowerCase().includes(value?.toString().toLowerCase() || ""));
            return (
              <div className="relative">
                {children}
                {inputFocus && filteredDataList && filteredDataList?.length > 0 && (
                  <div
                    ref={hintRef}
                    className={`
                      data-list-container
                      absolute left-0 right-0 z-[10]
                      ${dataListPosition === "top" ? "top-0 -translate-y-full rounded-t-sm border-b-0" : "bottom-0 translate-y-full rounded-b-sm border-t-0"}
                      w-full
                      flex flex-col 
                      bg-surface-top text-xs border border-border
                      overflow-y-scroll scrollbar-thin
                    `}
                    style={{ maxHeight: dataListHeight }}
                  >
                    {filteredDataList?.map((data) => (
                      <button
                        key={data}
                        onMouseDown={() => setClickingDataListOption(true)}
                        onClick={() => {
                          dataList?.onClick(data);
                          setInputFocus(false);
                        }}
                        className="block w-full text-start px-2 py-1 hover:bg-white/5"
                      >
                        {data}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            );
          }}
        >
          <div
            className={`
              input-container bg-transparent border border-border rounded-sm flex items-center justify-start gap-2
              ${small ? "py-1 px-2" : "p-2"}
            `}
          >
            {icon && (
              <div className="input-icon h-full inline-flex items-center justify-center text-xl">
                <Icon icon={getIcon(icon)} />
              </div>
            )}
            <input
              key={`input-${id}`}
              {...props}
              ref={ref}
              type={type || "text"}
              onFocus={() => setInputFocus(true)}
              onBlur={() =>
                setTimeout(() => {
                  if (!clickingDataListOption) setInputFocus(false);
                  setClickingDataListOption(false);
                }, 100)
              }
              className={`
                bg-transparent border-none flex-1 w-auto min-w-0
                focus:outline-none focus:ring-0 focus:border-none z-[2]
                text-sm p-0
                disabled:text-border
                ${type === "file" ? "hidden" : ""}
                ${className || ""}
              `}
            />
            {type === "file" && <FileInput {...props} />}
            {Array.isArray(buttons)
              ? buttons.map((props, i) => (
                  <Fragment key={i}>
                    {i !== 0 && <div className="h-full w-px bg-border" />}
                    <InputButton {...props} />
                  </Fragment>
                ))
              : buttons && <InputButton icon={buttons.icon} onClick={buttons.onClick} disabled={buttons.disabled} />}
          </div>
        </ConditionalWrapper>

        {children}

        {error && <p className="text-xs text-red-400">{error}</p>}
      </label>
    );
  })
);

export default Input;

const InputButton = ({ onClick, icon, disabled, isLoading, hidden }: InputButtonType) => {
  if (hidden) return null;
  return (
    <button onClick={onClick} disabled={disabled} className={`disabled:text-border`}>
      <Icon icon={getIcon(isLoading ? "loading" : icon)} />
    </button>
  );
};

export const FileInput = ({ id, className, ...props }: InputProps) => {
  return (
    <label className="w-full h-full border border-dashed border-border rounded-sm flex items-center justify-center uppercase p-2 cursor-pointer group">
      <div className="bg-background w-full h-full p-4 rounded-sm flex items-center justify-center gap-2 group-hover:bg-opacity-70 transition-colors duration-300 text-xs whitespace-nowrap">
        Upload Image <Icon icon={getIcon("upload")} className="text-xl" />
      </div>
      <input list={`file-input-${id}`} {...props} type="file" className={`hidden ${className}`} />
    </label>
  );
};

export const InputField = ({ input, label, error, children }: { input: ReactElement<InputProps>; label: string; error?: string; children?: ReactNode }) => {
  return (
    <div className="input-field flex flex-col gap-1 w-full">
      <label className="text-xs text-gray-400 pl-2">{label}</label>

      {cloneElement(input, { className: `${input.props.className || ""}` })}

      {children}

      {error && <p className="text-xs text-red-400">{error}</p>}
    </div>
  );
};

export const InputGroup = ({ children }: { children?: ReactNode | ReactNode[]; input?: InputProps | InputProps[] }) => {
  const multiple = Array.isArray(children);
  return (
    <ConditionalWrapper condition={multiple} wrapper={(children: ReactNode) => <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 w-full">{children}</div>}>
      {multiple ? children.map((child) => child) : children}
    </ConditionalWrapper>
  );
};
