import { useMemo, useState } from "react";
import Switcher, { SwitcherData } from "./Switcher";

const WeekPicker = ({ selectedDate, onDateChange }: { selectedDate?: Date; onDateChange: (date: Date) => void }) => {
  const [currentDate, setCurrentDate] = useState<Date>(selectedDate || new Date());

  const getStartOfWeek = (date: Date): Date => {
    const copy = new Date(date);
    const day = copy.getDay();
    const diff = day === 0 ? -6 : 1 - day;
    copy.setDate(copy.getDate() + diff);
    return copy;
  };

  const startOfWeek = getStartOfWeek(currentDate);

  const dayNames = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  const daysInWeek = Array.from({ length: 7 }, (_, index) => {
    const day = new Date(startOfWeek);
    day.setDate(startOfWeek.getDate() + index);
    return day;
  });

  const handleDateSelect = (date: Date) => {
    const selected = new Date(date);
    setCurrentDate(selected);
    onDateChange(selected);
  };

  const convertedDaysInWeek: SwitcherData[] = useMemo(() => {
    return daysInWeek.map((day) => ({
      children: dayNames[day.getDay()],
      onClick: () => handleDateSelect(day),
      isActive: currentDate.toDateString() === day.toDateString(),
      id: day.toDateString(),
    }));
    // eslint-disable-next-line
  }, [daysInWeek, currentDate, dayNames]);

  return <Switcher data={convertedDaysInWeek} />;
};

export default WeekPicker;
