import { Icon } from "@iconify/react";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import { Link } from "react-router-dom";
import { getIcon, IconType } from "../data/icons";

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  children: ReactNode;
  icon?: IconType;
  isLoading?: boolean;
  small?: boolean;
}

const Button = ({ children, icon, isLoading, small, className, disabled, ...props }: ButtonProps) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={`
        button group
        inline-flex gap-2 items-center justify-center relative text-sm rounded-sm
        cursor-pointer disabled:cursor-default
        bg-primary disabled:bg-disabled
        text-primaryContrast disabled:text-muted
        hover:shadow-[0_0_0_1px_rgb(var(--primary)),inset_0_0_0_10px_rgb(var(--primary))] 
        disabled:hover:shadow-none
        transition-shadow duration-300
        ${small ? "p-1" : "p-2"}
        ${className || ""}
      `}
    >
      <div className={`button-children flex items-center justify-center w-full uppercase ${small ? "px-4" : "px-8"}`}>{children}</div>
      {icon && (
        <div className="inline-flex justify-self-end items-center justify-center h-full shrink-0">
          <div className="px-3 py-1 border-l border-primaryContrast/30">
            <Icon icon={getIcon(isLoading ? "loading" : disabled ? "x-circle" : icon)} fontSize={16} className="shrink-0" />
          </div>
        </div>
      )}
    </button>
  );
};

interface LinkButtonProps extends ButtonProps {
  to: string;
}

export default Button;

export const LinkButton = ({ to, ...props }: LinkButtonProps) => {
  return (
    <Link to={to}>
      <Button {...props} />
    </Link>
  );
};
