import { collection, onSnapshot, Unsubscribe } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { useFlainContext } from "../../../App";
import Container from "../../../components/Container";
import { FlainContextType } from "../../../data/types";
import { db } from "../../../firebase/firebase";
import ChartExpensesByTags from "../components/ChartExpensesByTags";
import { ExpenseInterface } from "../types";
import { StatisticsContextType } from "./types";

export const StatisticsContext = createContext<StatisticsContextType | undefined>(undefined);
export const useStatisticsContext = () => useContext(StatisticsContext);

const Stats = () => {
  const { user } = useFlainContext() as FlainContextType;

  const [expenses, setExpenses] = useState<ExpenseInterface[]>([]);

  useEffect(() => {
    if (!user) return;

    const subscriptions: Unsubscribe[] = [];

    const unsubExpenses = onSnapshot(collection(db, "expenses"), (querySnapshot) => {
      const tempExpenses: ExpenseInterface[] = [];
      querySnapshot.forEach((doc) => {
        tempExpenses.push(doc.data() as ExpenseInterface);
      });
      setExpenses(tempExpenses);
    });

    subscriptions.push(unsubExpenses);

    return () => {
      subscriptions.forEach((unSub) => unSub());
    };
  }, [user]);

  return (
    <StatisticsContext.Provider value={{ expenses, setExpenses }}>
      <Container background>
        <ChartExpensesByTags />
      </Container>

      {/* <Container background>
        <div className="w-full aspect-square">
          <LineChart
            series={[
              { data: totalExpensesPerDay, curve: "linear", id: "all-expenses", showMark: false, connectNulls: true },
              { data: foodExpenses, curve: "linear", showMark: false, connectNulls: true },
            ]}
            xAxis={[{ scaleType: "point", data: daysOfMonth, tickInterval: (index, i) => (totalExpensesPerDay[i] ? totalExpensesPerDay[i]! > 0 : false) }]}
            yAxis={[
              {
                max:
                  Math.max(...(totalExpensesPerDay as number[])) === 0
                    ? Math.max(...(totalExpensesPerDay as number[])) + 1
                    : Math.max(...(totalExpensesPerDay as number[])),
                min: Math.min(...(totalExpensesPerDay as number[])),
                tickMaxStep: (totalExpensesPerDay.length * 100) / 4,
                tickMinStep: 1,
                scaleType: "sqrt",
              },
            ]}
            tooltip={{ trigger: "axis" }}
            leftAxis={{
              disableTicks: true,
              disableLine: true,
              tickLabelStyle: {
                angle: 45,
                fontSize: 10,
                fill: "var(--text)",
              },
            }}
            bottomAxis={{
              disableLine: true,
              disableTicks: true,
              tickLabelStyle: {
                fontSize: 10,
                fill: "var(--text)",
              },
            }}
            margin={{ left: 30, bottom: 20, top: 30, right: 0 }}
            className="h-full w-full"
            sx={{
              [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
                strokeWidth: 1,
              },
              ".MuiLineElement-series-all-expenses": {
                strokeDasharray: "5 5",
              },
            }}
          >
            <ChartsReferenceLine
              y={Math.max(...(totalExpensesPerDay as number[]))}
              labelStyle={{ color: "var(--text)", fontSize: 12 }}
              lineStyle={{ stroke: "rgb(var(--border))", strokeDasharray: "5 5", strokeWidth: 1 }}
            />
          </LineChart>
        </div>
        {month}
        <div className="flex gap-2">
          <button onClick={() => handleMonth("back")}>
            <Icon icon={getIcon("chevron-left")} />
          </button>
          <button onClick={() => handleMonth("forward")}>
            <Icon icon={getIcon("chevron-right")} />
          </button>
        </div>
      </Container> */}
    </StatisticsContext.Provider>
  );
};

export default Stats;
