import { loadIcons } from "@iconify/react";
import { RgbColor } from "colord";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { onMessage } from "firebase/messaging";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Loader from "./components/Loader";
import Login from "./components/Login";
import NavigationBar from "./components/NavigationBar";
import iconsets, { iconset } from "./data/icons";
import { FlainContextType, Settings, TempColor, UserData } from "./data/types";
import { db, messaging, writeCurrentTokenToDatabase } from "./firebase/firebase";
import { rgb2tailwind, tailwind2rgb } from "./helper/colorHelper";
import NewExpense from "./routes/finances/NewExpense";
import NewFood from "./routes/food/NewFood";
import NewReminder from "./routes/reminders/NewReminder";
import routes, { containsPath, navigationRoutes } from "./routes/routes";
import "./styles/main.scss";

export const FlainContext = createContext<FlainContextType | undefined>(undefined);
export const useFlainContext = () => useContext(FlainContext);

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | undefined>();
  const [users, setUsers] = useState<UserData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState<Settings>({
    font: "OpenSans",
    colors: {
      primary: tailwind2rgb(getComputedStyle(document.body).getPropertyValue("--primary")),
      primaryContrast: tailwind2rgb(getComputedStyle(document.body).getPropertyValue("--primary-contrast")),
    },
  });
  const [initialColor, setInitialColor] = useState<RgbColor | undefined>();
  const [tempColor, setTempColor] = useState<TempColor | undefined>();

  //const navigate = useNavigate();

  useEffect(() => {
    let unsub: Unsubscribe = () => {};
    onAuthStateChanged(getAuth(), async (u) => {
      console.info("auth state changed");
      setUser(u);
      if (u) {
        await writeCurrentTokenToDatabase();
        unsub = onSnapshot(doc(db, "users", u.uid), (doc) => {
          const data = doc.data() as UserData;
          setUserData({ ...data, uid: doc.id });
        });
      }
      setIsLoading(false);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    if (!user) return;
    const unsub = onSnapshot(collection(db, "users"), (snapshot) => {
      snapshot.forEach((doc) => {
        const data = doc.data() as UserData;
        setUsers((prev) => [...prev, { ...data, uid: doc.id }]);
      });
    });
    return () => unsub();
  }, [user]);

  /* const handleSwiped = (eventData: SwipeEventData) => {
    if (eventData.dir === "Right") {
      navigate(-1);
    } else if (eventData.dir === "Left") {
      navigate(1);
    }
  }; */

  // Initialize useSwipeable and cast ref to work with Document
  /* const { ref: swipeableRef } = useSwipeable({
    onSwiped: handleSwiped,
    //onTouchStartOrOnMouseDown: ({ event }) => event.preventDefault(),
    touchEventOptions: { passive: false },
    trackMouse: true, // Track mouse for swipe gestures
  }) as { ref: React.RefCallback<Document> };

  useEffect(() => {
    swipeableRef(document);

    return () => swipeableRef(null);
  }, [swipeableRef]); */

  useEffect(() => {
    async function iconLoader() {
      const iconIDs = Object.values(iconsets[iconset]);
      if (!iconIDs.length) return;

      return new Promise((fulfill, reject) => {
        loadIcons(iconIDs, (loaded, missing, pending) => {
          if (!pending.length && !missing.length) {
            fulfill({ loaded });
          } else {
            reject({ loaded, missing });
          }
        });
      });
    }

    iconLoader()
      .then(() => console.info("All icons loaded."))
      .catch((error) => console.error("Failed to load all icons: ", error));
  }, []);

  useEffect(() => {
    if (!user) return;

    const ref = collection(db, "settings");

    const unsub = onSnapshot(ref, (snapshot) => {
      const userDoc = snapshot.docs.find((doc) => doc.id === user.uid);

      if (userDoc) {
        const snapSettings = userDoc.data() as Settings;
        setSettings(snapSettings);
        setInitialColor(snapSettings.colors.primary);
      }
    });

    return () => unsub();
  }, [user]);

  const applyColors = useCallback(
    (color?: { primary?: RgbColor; primaryContrast?: RgbColor }) => {
      const html = document.documentElement;
      const newPrimary = rgb2tailwind(color?.primary || settings.colors.primary);
      const currentPrimary = getComputedStyle(html).getPropertyValue("--primary");

      if (newPrimary !== currentPrimary) {
        html.style.setProperty("--primary", newPrimary);
        html.style.setProperty("--primary-contrast", rgb2tailwind(color?.primaryContrast || settings.colors.primaryContrast));
      }
    },
    [settings.colors]
  );

  useEffect(() => {
    const body = document.body;
    body.style.setProperty("--font", `var(--font-${settings.font})`);
  }, [settings.font]);

  useEffect(() => {
    applyColors(settings.colors);
    // eslint-disable-next-line
  }, [settings.colors]);

  useEffect(() => {
    applyColors(tempColor);
    // eslint-disable-next-line
  }, [tempColor]);

  onMessage(messaging, (payload) => {
    toast(<div>{payload?.data?.url ? <Link to={payload?.data?.url || ""}>{payload?.data?.body}</Link> : payload?.data?.body}</div>);
  });

  const { pathname } = useLocation();

  if (isLoading) return <Loader />;
  if (!user) return <Login />;
  else
    return (
      <>
        <FlainContext.Provider
          value={{
            user,
            settings,
            userData,
            setUserData,
            users,
            setUsers,
            setSettings,
            initialColor,
            tempColor,
            setTempColor,
          }}
        >
          <Header />
          <Outlet />
          {containsPath(navigationRoutes.finances, pathname) && <NavigationBar addElement={<NewExpense />} items={navigationRoutes.finances} />}
          {pathname === routes.index.children.food.path && <NavigationBar addElement={<NewFood />} items={[]} />}
          {pathname === routes.index.children.reminders.path && <NavigationBar addElement={<NewReminder />} items={[]} />}
        </FlainContext.Provider>
      </>
    );
}

export default App;
