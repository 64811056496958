export type IconsetType = "fluent";
export type IconType =
  | "home"
  | "signOut"
  | "signIn"
  | "x"
  | "x-circle"
  | "check"
  | "plus"
  | "plus-circle"
  | "subtract-circle"
  | "chevron-left"
  | "chevron-right"
  | "chevron-left-circle"
  | "chevron-right-circle"
  | "chevron-left-double"
  | "chevron-right-double"
  | "caret-down"
  | "caret-up"
  | "food"
  | "money"
  | "settings"
  | "trash"
  | "send"
  | "upload"
  | "external-link"
  | "appearance"
  | "notification"
  | "notification-off"
  | "chart"
  | "404"
  | "todo"
  | "loading"
  | "info"
  | "info-animated"
  | "clock"
  | "calendar"
  | "location"
  | "my-location"
  | "heart"
  | "copy"
  | "edit"
  | "edit-off"
  | "reset"
  | "mail"
  | "password"
  | "lock"
  | "eye"
  | "eye-off"
  | "tag"
  | "tag-reset"
  | "font-upload"
  | "update-text"
  | "ellipsis-vertical"
  | "user"
  | "user-circle";

type IconsetsType = {
  [iconset in IconsetType]: Record<IconType, string>;
};

export const iconset: IconsetType = "fluent";

export const iconsets: IconsetsType = {
  fluent: {
    home: "fluent:home-24-regular",
    signOut: "fluent:sign-out-24-regular",
    signIn: "fluent:arrow-join-20-regular",
    x: "fluent:dismiss-24-regular",
    "x-circle": "fluent:dismiss-circle-24-regular",
    check: "fluent:checkmark-24-regular",
    plus: "fluent:add-24-regular",
    "plus-circle": "fluent:add-circle-24-regular",
    "subtract-circle": "fluent:subtract-circle-24-regular",
    "chevron-left": "fluent:chevron-left-24-regular",
    "chevron-right": "fluent:chevron-right-24-regular",
    "chevron-left-circle": "fluent:chevron-circle-left-24-regular",
    "chevron-right-circle": "fluent:chevron-circle-right-24-regular",
    "chevron-left-double": "fluent:chevron-double-left-20-regular",
    "chevron-right-double": "fluent:chevron-double-right-20-regular",
    "caret-down": "fluent:caret-down-24-regular",
    "caret-up": "fluent:caret-up-24-regular",
    food: "fluent:food-pizza-24-regular",
    money: "fluent:money-24-regular",
    settings: "fluent:settings-24-regular",
    trash: "bi:trash3",
    send: "fluent:send-24-regular",
    upload: "fluent:arrow-upload-24-regular",
    "external-link": "fluent:open-24-regular",
    appearance: "fluent:paint-brush-sparkle-24-regular",
    notification: "fluent:alert-24-regular",
    "notification-off": "fluent:alert-off-24-regular",
    chart: "fluent:chart-multiple-24-regular",
    "404": "fluent:slide-search-24-regular",
    todo: "fluent:clipboard-task-list-24-regular",
    loading: "line-md:loading-twotone-loop",
    info: "fluent:info-24-regular",
    "info-animated": "line-md:alert-circle",
    clock: "fluent:clock-24-regular",
    calendar: "fluent:calendar-24-regular",
    location: "fluent:location-ripple-24-regular",
    "my-location": "fluent:my-location-24-regular",
    heart: "fluent:heart-24-regular",
    copy: "fluent:copy-24-regular",
    edit: "fluent:edit-24-regular",
    "edit-off": "fluent:edit-off-24-regular",
    reset: "fluent:arrow-clockwise-24-regular",
    mail: "fluent:mail-24-regular",
    password: "fluent:password-24-regular",
    lock: "fluent:lock-closed-24-regular",
    eye: "line-md:watch-loop",
    "eye-off": "line-md:watch-off-loop",
    tag: "fluent:tag-24-regular",
    "tag-reset": "fluent:tag-reset-24-regular",
    "font-upload": "fluent:font-increase-24-regular",
    "update-text": "fluent:slide-text-edit-24-regular",
    "ellipsis-vertical": "fluent:more-vertical-24-regular",
    user: "fluent:person-24-regular",
    "user-circle": "fluent:person-circle-24-regular",
  },
};

export const getIcon = (icon: IconType) => iconsets[iconset][icon];

export default iconsets;
