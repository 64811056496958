import { RgbColor } from "colord";
import { User } from "firebase/auth";
import { Dispatch } from "react";

export interface UserData {
  uid: string;
  displayName: string;
  notificationTokens: string[];
  isAdmin?: boolean;
}

export interface FlainContextType {
  user: User | null;
  settings: Settings;
  userData: UserData | undefined;
  setUserData: Dispatch<UserData | undefined>;
  users: UserData[];
  setUsers: Dispatch<UserData[]>;
  setSettings: Dispatch<Settings>;
  initialColor: RgbColor | undefined;
  tempColor: TempColor | undefined;
  setTempColor: Dispatch<TempColor | undefined>;
}

export interface Settings {
  font: Font;
  colors: {
    primary: RgbColor;
    primaryContrast: RgbColor;
  };
}

export interface TempColor {
  primary: RgbColor | undefined;
  primaryContrast: RgbColor | undefined;
}

export type Font = (typeof fonts)[number];
export const fonts = ["OpenSans", "Montserrat", "Fredoka", "Poppins"] as const;

declare global {
  interface Date {
    flainGetTimeString(): string;
  }
  interface String {
    flainGetTimeString(): string;
    flainFirstLetterUppercase(): boolean;
  }
}
