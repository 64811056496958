import { Icon } from "@iconify/react";
import { ReactNode } from "react";
import { getIcon } from "../data/icons";
import ConditionalWrapper from "./ConditionalWrapper";

export interface SwitcherData {
  children: string;
  onClick: () => void;
  isActive: boolean;
  id: string;
  disabled?: boolean;
  showCheckmark?: boolean;
}

const Switcher = ({ data }: { data: SwitcherData[] }) => {
  return (
    <div className="switcher flex text-sm border border-border rounded-sm w-full p-1 gap-1">
      {data.map(({ children, onClick, isActive = false, id, disabled = false, showCheckmark }) => {
        return (
          <button
            key={id}
            className={`
              w-full h-full rounded-sm transition-colors duration-300 p-1 
              ${isActive ? "bg-primary text-primaryContrast hover:bg-primary/80" : "hover:bg-border/50"}
            `}
            onClick={onClick}
            disabled={disabled}
          >
            <ConditionalWrapper
              condition={showCheckmark && isActive}
              wrapper={(children: ReactNode) => (
                <div className="flex gap-1 items-center justify-center">
                  <span className="">{children}</span>
                  <Icon icon={getIcon("check")} className="translate-y-px" />
                </div>
              )}
            >
              {children}
            </ConditionalWrapper>
          </button>
        );
      })}
    </div>
  );
};

export default Switcher;
