export const createId = () => Math.random().toString(16).slice(2);

export const currency = (amount: number) =>
  new Intl.NumberFormat("en-MY", {
    style: "decimal",
    currency: "MYR",
  }).format(amount);

export const copy = (string: string) => {
  return new Promise<void>((resolve) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(string)
        .then(() => resolve())
        .catch((err) => console.error(err));
    } else {
      resolve(console.error("Clipboard API not supported"));
    }
  });
};

/*eslint no-extend-native: ["error", { "exceptions": ["String", "Date"] }]*/
/** Helper function to get time string in 'HH:mm:ss' format */
Date.prototype.flainGetTimeString = function () {
  this.setSeconds(0);
  return this.toTimeString().split(" ")[0].slice(0, 5); // Extract only 'HH:mm:ss'
};
/** Helper function to get time string in 'HH:mm:ss' format */
String.prototype.flainGetTimeString = function () {
  return this.slice(0, 5); // Extract only 'HH:mm'
};
/** Helper function to check if the first letter of a string is uppercase */
String.prototype.flainFirstLetterUppercase = function () {
  return this[0] !== this[0].toLowerCase();
};
