import { collection, onSnapshot, orderBy, query, Timestamp, where } from "firebase/firestore";
import { memo, useEffect, useState } from "react";
import { useFlainContext } from "../../App";
import { FlainContextType } from "../../data/types";
import { db } from "../../firebase/firebase";
import { formattedDate } from "../../helper/dateHelper";
import Expense from "./Expense";
import { ExpenseInterface } from "./types";

const Day = ({ date }: { date: Date }) => {
  const { user } = useFlainContext() as FlainContextType;
  const [expenses, setExpenses] = useState<ExpenseInterface[]>([]);

  useEffect(() => {
    if (!(date && user)) return;
    const q = query(collection(db, "expenses"), where("date.bought", "==", Timestamp.fromDate(date)), orderBy("date.bought"));

    const unsub = onSnapshot(q, (querySnapshot) => {
      const e: ExpenseInterface[] = [];
      querySnapshot.forEach((doc) => {
        e.push({ ...(doc.data() as ExpenseInterface), id: doc.id });
      });
      setExpenses(e);
    });

    return () => unsub();
  }, [date, user]);

  const hasExpenses = Boolean(expenses.length);

  return (
    <div className="relative group py-4">
      <div className="absolute top-[25px] left-2 h-full flex flex-col items-center">
        <div className="min-w-[9px] -translate-y-1/2 min-h-[9px] absolute shrink-0 rounded-full bg-border shadow-surface shadow-[0_0_0_4px] z-[2]" />
        <div className="h-full w-px bg-border group-last:hidden" />
      </div>
      <div className="day pl-7 flex flex-col gap-1">
        <h4 className={`font-semibold text-sm ${hasExpenses ? "" : "text-text-secondary"}`}>{formattedDate(date)}</h4>
        {hasExpenses && (
          <div className="p-2 bg-surface-top rounded-sm">
            {expenses.length > 0 && (
              <>
                <div className="flex flex-wrap gap-2">
                  {expenses.map((expense) => (
                    <Expense key={expense.id} data={expense} />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Day);
