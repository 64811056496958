import { addDoc, collection, serverTimestamp, Timestamp } from "firebase/firestore";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useFlainContext } from "../../App";
import Button from "../../components/Button";
import Headline from "../../components/Headline";
import Input, { InputField, InputGroup } from "../../components/Input";
import { AddButton } from "../../components/NavigationBar";
import Popover from "../../components/Popover";
import Switcher, { SwitcherData } from "../../components/Switcher";
import WeekPicker from "../../components/Weekpicker";
import { FlainContextType } from "../../data/types";
import { db } from "../../firebase/firebase";
import { frequencies, Frequency, ReminderBase } from "./types";

const NewReminder = () => {
  const { userData, users } = useFlainContext() as FlainContextType;
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [time, setTime] = useState(new Date().flainGetTimeString());
  const [date, setDate] = useState(new Date());
  const [frequency, setFrequency] = useState<Frequency>("Daily");
  const [subscribers, setSubscribers] = useState<string[]>([]);

  useEffect(() => {
    if (!userData?.uid) return;
    const includes = subscribers.includes(userData.uid);
    if (!includes) setSubscribers((prev) => [...prev, userData.uid]);
  }, [userData, subscribers]);

  const validate = useMemo(() => {
    const validTitle = Boolean(title.trim());
    return { title: validTitle, all: validTitle };
  }, [title]);

  const frequencyOptions = useMemo(
    () =>
      frequencies.map((f, i) => (
        <option key={i} value={f}>
          {f}
        </option>
      )),
    []
  );

  const handleTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);

  const handleTimeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value.flainGetTimeString());
  }, []);

  const handleFrequency = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setFrequency(e.target.value as Frequency);
  }, []);

  const handleDate = useCallback((date: Date | null) => {
    if (!date) return;
    setDate(date);
  }, []);

  const submit = useCallback(async () => {
    if (!validate.all) return;

    const submitData: ReminderBase = {
      title,
      time,
      date: {
        day: date.getDay(),
        dayOfMonth: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
        timestamp: Timestamp.fromDate(date),
      },
      createdAt: serverTimestamp(),
      frequency,
      done: false,
      subscribers: subscribers,
    };

    await addDoc(collection(db, "reminders"), submitData)
      .then(() => {
        toast(`Added reminder "${title}"`);
        setIsOpen(false);
      })
      .catch((error) => console.error(error));
  }, [validate.all, title, time, date, frequency, subscribers]);

  const userSwitcherData: SwitcherData[] = useMemo(() => {
    return users.map(({ displayName, uid }) => ({
      children: displayName,
      id: uid,
      showCheckmark: true,
      onClick: () =>
        setSubscribers((prev) => {
          const subs = [...prev];
          const includes = subs.includes(uid);
          if (!includes) subs.push(uid);
          else return subs.filter((s) => s !== uid);
          return subs;
        }),
      isActive: subscribers.includes(uid),
    }));
  }, [users, subscribers]);

  return (
    <Popover id="overlay-new-food" isOpen={isOpen} setIsOpen={setIsOpen} trigger={<AddButton active={isOpen} />}>
      <div className="flex flex-col items-center gap-2 p-4">
        <Headline className="text-center">New Reminder</Headline>
        <InputGroup>
          <InputField label="Title" input={<Input value={title} onChange={handleTitle} />} />
          <InputField label="Time" input={<Input type="time" value={time} onChange={handleTimeInput} />} />
        </InputGroup>

        <InputField
          label="Frequency"
          input={
            <select value={frequency} onChange={handleFrequency}>
              {frequencyOptions}
            </select>
          }
        />

        {frequency === "Weekly" && <InputField label="Day" input={<WeekPicker selectedDate={date} onDateChange={handleDate} />} />}
        {frequency === "Once" && (
          <InputField label="Date" input={<DatePicker selected={date} onChange={handleDate} dateFormat="dd.MM.yyyy" customInput={<Input />} />} />
        )}

        <InputField label="Subscribe" input={<Switcher data={userSwitcherData} />} />
        <hr className="my-0" />
        <Button onClick={submit} disabled={!validate.all}>
          Submit
        </Button>
      </div>
    </Popover>
  );
};

export default NewReminder;
