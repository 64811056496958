import { Icon, iconExists } from "@iconify/react";
import { deleteDoc, doc } from "firebase/firestore";
import { memo, ReactNode, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import ConditionalWrapper from "../../components/ConditionalWrapper";
import Headline, { SubHeadline } from "../../components/Headline";
import Overlay from "../../components/Overlay";
import Tag from "../../components/Tag";
import { db } from "../../firebase/firebase";
import { formattedDate, formattedDateWithTime } from "../../helper/dateHelper";
import { currency } from "../../helper/stringHelper";
import { ExpenseInterface } from "./types";

const Expense = ({ data }: { data: ExpenseInterface }) => {
  const { expense, id, price, date, relationFor, relationFrom, tags } = data;
  const [overlayOpen, setOverlayOpen] = useState(false);

  const deleteExpense = async (id: string) => {
    if (!id) return toast("no id");
    await deleteDoc(doc(db, "expenses", id))
      .catch((error) => console.error(error))
      .then(() => toast(`Doc ${expense} has been deleted.`));
  };

  return (
    <>
      <div className="bg-surface border border-border rounded-sm flex overflow-hidden" onClick={() => setOverlayOpen(true)}>
        <div className="flex items-center text-sm p-1 cursor-pointer text-center">
          <p className="font-semibold px-2">{expense}</p>
          <p className="bg-tag rounded-sm px-2 text-center">
            <span className="text-[8px] mr-0.5 text-text-secondary">RM</span>
            <span className="text-md font-semibold">{currency(price)}</span>
          </p>
        </div>
      </div>

      <Overlay id={`overlay-expense-${id}`} isOpen={overlayOpen} setIsOpen={setOverlayOpen}>
        <div className="flex flex-col items-center w-full p-4 gap-4">
          <Headline>{expense}</Headline>
          <table className="w-full">
            <tbody>
              <InfoRow
                title="Price"
                info={
                  <>
                    <span className="text-xs mr-0.5 text-text-secondary">RM</span>
                    <span className="text-xl font-semibold">{currency(price)}</span>
                  </>
                }
              />
              <InfoRow title="Bought" info={formattedDate(date.bought.seconds * 1000)} />
              <InfoRow title="Created" info={formattedDateWithTime(date.created.seconds * 1000)} />
              <InfoRow title="From" info={relationFrom || "-"} />
              <InfoRow title="For" info={relationFor || "-"} />
            </tbody>
          </table>
          <div className="flex flex-col gap-2 w-full">
            <SubHeadline>Tags</SubHeadline>
            <div className="flex flex-wrap gap-1">
              {tags?.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </div>
          </div>

          <hr />

          <Button onClick={() => deleteExpense(id)} icon="trash" small>
            Delete
          </Button>

        </div>
      </Overlay>
    </>
  );
};

export default memo(Expense);

const InfoRow = ({ title, info, icon }: { title: string; info: ReactNode; icon?: string }) => {
  return (
    <tr className=" even:bg-background">
      <td className="font-semibold text-xs pr-4 py-1 px-2">
        <ConditionalWrapper condition={Icon !== undefined} wrapper={(children: ReactNode) => <div className="flex gap-1 items-center">{children}</div>}>
          {icon && iconExists(icon) && <Icon icon={icon} fontSize={14} className="text-gray-400" />}
          {title}
        </ConditionalWrapper>
      </td>
      <td className="py-1 px-2 text-sm">{info}</td>
    </tr>
  );
};
