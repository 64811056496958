import { ReactNode } from "react";

const Tag = ({ children, active = false, onClick }: { children: ReactNode; active?: boolean; onClick?: () => void }) => {
  return (
    <div className={`tag py-1 px-3 text-xs rounded-full cursor-pointer ${active ? "bg-primary text-primaryContrast" : "bg-tag text-text"}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default Tag;
