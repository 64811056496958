import { FieldValue, Timestamp } from "firebase/firestore";

export type Frequency = "Once" | "Daily" | "Weekly";
export type Frequencies = Frequency[];

export const frequencies: Frequencies = ["Once", "Daily", "Weekly"];

export interface Reminder extends ReminderBase {
  id: string;
  nextOccurance: Date;
}
export interface ReminderBase {
  title: string;
  time: string;
  date: {
    day: number;
    dayOfMonth: number;
    month: number;
    year: number;
    timestamp: Timestamp;
  };
  createdAt: FieldValue;
  frequency: Frequency;
  done: boolean;
  subscribers: string[];
}
