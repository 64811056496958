import { colord, extend, RgbColor } from "colord";
import a11yPlugin from "colord/plugins/a11y";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { RgbColorPicker } from "react-colorful";
import { toast } from "react-toastify";
import colors from "tailwindcss/colors";
import { useFlainContext } from "../../App";
import Button from "../../components/Button";
import Container from "../../components/Container";
import { FlainContextType, Font, fonts } from "../../data/types";
import { db } from "../../firebase/firebase";
import SettingsSection from "./SettingsSection";

extend([a11yPlugin]);

export const SettingsAppearance = () => {
  const { user } = useFlainContext() as FlainContextType;
  const { settings, initialColor, tempColor, setTempColor } = useFlainContext() as FlainContextType;
  const [font, setFont] = useState<Font>(settings.font);

  const valid = useMemo(() => {
    const c = true; // settings.colors.primary !== initialColor;
    console.log("font: ", settings.font, font);
    const f = font !== settings.font;
    return { color: c, font: f, all: c && f };
  }, [font, settings.font]);

  useEffect(() => {
    //console.log("font: ", settings.font);
  }, [font]);

  const [swatches, setSwatches] = useState<RgbColor[]>([
    { r: 97, g: 160, b: 168 },
    { r: 255, g: 149, b: 79 },
    { r: 255, g: 242, b: 122 },
    { r: 251, g: 173, b: 54 },
    { r: 250, g: 221, b: 135 },
  ]);

  const handleColor = (color: RgbColor) => {
    const colorDark = colors.gray[800];
    const colorLight = colors.gray[50];
    const contrast = colord(color).contrast(colorDark);
    const contrastColor = contrast > 21 / 2 ? colorDark : colorLight;
    const primaryContrastRGBA = colord(contrastColor).toRgb();
    // fucking nightmare to remove the alpha from RGBA. why does `toRgb` create an RGBA anyway.
    const primaryContrast = { ...primaryContrastRGBA } as Partial<{
      r: number;
      g: number;
      b: number;
      a?: number;
    }>;
    delete primaryContrast.a;
    setTempColor({
      primary: color,
      primaryContrast: primaryContrast as RgbColor,
    });
  };

  useEffect(() => {
    if (initialColor) {
      const initInPreset = swatches.some((e) => e.r === initialColor.r && e.g === initialColor.g && e.b === initialColor.b);
      if (!initInPreset) setSwatches([...swatches, initialColor]);
    }
    // eslint-disable-next-line
  }, [initialColor]);

  const submitColor = async () => {
    if (!user) return;
    const ref = doc(db, "settings", user.uid);
    await setDoc(
      ref,
      {
        colors: tempColor,
      },
      { merge: true }
    )
      .catch((error) => console.error(error))
      .then(() =>
        toast(
          <div className="flex items-center gap-2">
            Set Color to{" "}
            <span className="w-4 h-4 rounded-full" style={{ backgroundColor: tempColor?.primary ? colord(tempColor?.primary).toHex() : "#ffffff" }} />
          </div>
        )
      );
  };

  // Fonts

  const example = "The quick brown fox jumps over the lazy dog";

  useEffect(() => {
    setFont(document.body.style.fontFamily as Font);
  }, []);

  const updateFont = async () => {
    if (!user) return;
    updateDoc(doc(db, "settings", user.uid), {
      font: font,
    })
      .then(() => toast(`Set font to "${font}"`))
      .catch((error) => console.error(error));
  };

  const handleFont = async (font: Font) => setFont(font);

  return (
    <>
      <Container background small>
        <SettingsSection title="Primary Color">
          <div className="color-picker w-full">
            <RgbColorPicker onChange={handleColor} color={settings.colors.primary} />
            <div className="color-picker-swatches">
              {swatches.map((c) => {
                const color = colord(c).toHex();
                return (
                  <button
                    key={color}
                    onClick={() => handleColor(c)}
                    className={`color-picker-swatch`}
                    style={
                      {
                        backgroundColor: color,
                        "--swatch-color": color,
                      } as React.CSSProperties
                    }
                  />
                );
              })}
            </div>
          </div>
          <Button onClick={submitColor} disabled={!valid.color} small icon="appearance">
            Submit
          </Button>
        </SettingsSection>
      </Container>

      <Container small background>
        <SettingsSection title="Font">
          <select onChange={(e) => handleFont(e.target.value as Font)}>
            {fonts.map((font) => (
              <option key={font} value={font}>
                {font}
              </option>
            ))}
          </select>
          <Button onClick={updateFont} icon="font-upload" small disabled={!valid.font}>
            Update
          </Button>
          <div className="flex flex-col w-full">
            {["text-xs", "text-sm", "text-base", "text-lg", "text-xl", "text-2xl", "text-3xl", "text-4xl", "text-5xl"].map((fontSize, sizeIndex) => {
              return (
                <Fragment key={sizeIndex}>
                  <div className="w-full flex items-center gap-2 mt-3 mb-1">
                    <p style={{ fontFamily: `var(--font-${font})` }} className={`${fontSize} text-text-secondary font-semibold`}>
                      {fontSize.replace("text-", "")}
                    </p>
                    <div className="flex-1 h-0 border-b border-border border-dotted" />
                  </div>

                  <div className="overflow-x-auto scrollbar-thin">
                    {["font-light", "font-normal", "font-medium", "font-semibold", "font-bold"].map((fontWeight, weightIndex) => {
                      return (
                        <p key={weightIndex} style={{ fontFamily: `var(--font-${font})` }} className={`${fontSize} ${fontWeight} whitespace-nowrap py-1`}>
                          {example}
                        </p>
                      );
                    })}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </SettingsSection>
      </Container>
    </>
  );
};
