import { Icon } from "@iconify/react";
import { collection, onSnapshot, orderBy, query, Timestamp, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFlainContext } from "../../App";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import { getIcon } from "../../data/icons";
import { FlainContextType } from "../../data/types";
import { db } from "../../firebase/firebase";
import { datesOfWeek, formattedMonthAndYear, getDaysOfMonth } from "../../helper/dateHelper";
import Day from "./Day";
import Sum from "./Sum";

function Finances() {
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState<Date>(new Date());
  const [week, setWeek] = useState<Date[]>(datesOfWeek(new Date(date)));

  const [weeksExpenses, setWeeksExpenses] = useState<number[]>([]);
  const [weeksExpensesSum, setWeeksExpensesSum] = useState<number>(0);

  const [monthsExpenses, setMonthsExpenses] = useState<number[]>([]);
  const [monthsExpensesSum, setMonthsExpensesSum] = useState<number>(0);

  const { user } = useFlainContext() as FlainContextType;

  useEffect(() => {
    if (date) {
      setWeek(datesOfWeek(new Date(date)));
    }
  }, [date]);

  const pickDate = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    setDate(date);
  };

  // get weekly expenses
  useEffect(() => {
    if (!(user && week?.length > 0)) return;

    const q = query(
      collection(db, "expenses"),
      where("date.bought", ">=", Timestamp.fromDate(week[0])),
      where("date.bought", "<=", Timestamp.fromDate(week[week.length - 1])),
      orderBy("date.bought")
    );

    const unsub = onSnapshot(q, (querySnapshot) => {
      const tempExpenses: number[] = [];
      querySnapshot.forEach((doc) => {
        tempExpenses.push(doc.data().price);
      });
      setWeeksExpenses(tempExpenses);
    });

    return () => unsub();
  }, [date, week, user]);

  // get monthly expenses
  useEffect(() => {
    if (!(user && date)) return;

    const q = query(
      collection(db, "expenses"),
      where("date.bought", ">=", Timestamp.fromDate(getDaysOfMonth(date).first)),
      where("date.bought", "<=", Timestamp.fromDate(getDaysOfMonth(date).last)),
      orderBy("date.bought")
    );

    const unsub = onSnapshot(q, (querySnapshot) => {
      const tempExpenses: number[] = [];
      querySnapshot.forEach((doc) => {
        tempExpenses.push(doc.data().price);
      });
      setMonthsExpenses(tempExpenses);
    });

    return () => unsub();
  }, [user, date]);

  const calculateSum = useCallback((expenses: number[]) => expenses.reduce((a, b) => a + b, 0), []);

  useEffect(() => {
    const weeksSum = calculateSum(weeksExpenses);
    const monthsSum = calculateSum(monthsExpenses);

    if (weeksSum !== weeksExpensesSum) setWeeksExpensesSum(weeksSum);
    if (monthsSum !== monthsExpensesSum) setMonthsExpensesSum(monthsSum);
    setLoading(false);
  }, [weeksExpenses, monthsExpenses, calculateSum, weeksExpensesSum, monthsExpensesSum]);

  return (
    <>
      <Container className="-mt-8">
        <div className="z-[1] mt-4 -mb-24">
          <DatePicker
            calendarClassName="datepicker-default"
            selected={date}
            onChange={(d) => d && pickDate(d)}
            inline
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              increaseYear,
              decreaseYear,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              prevYearButtonDisabled,
              nextYearButtonDisabled,
            }) => (
              <div className="flex justify-between items-center px-4 text-lg font-semibold">
                <div className="inline-flex gap-1">
                  <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
                    <Icon icon={getIcon("chevron-left-double")} />
                  </button>
                  <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <Icon icon={getIcon("chevron-left")} />
                  </button>
                </div>
                <span>{formattedMonthAndYear(date)}</span>
                <div className="inline-flex gap-1">
                  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <Icon icon={getIcon("chevron-right")} />
                  </button>
                  <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
                    <Icon icon={getIcon("chevron-right-double")} />
                  </button>
                </div>
              </div>
            )}
          />
        </div>
      </Container>

      <Container background>
        <div className="flex flex-col gap-2 pt-14">
          <Sum week={weeksExpensesSum} month={monthsExpensesSum} />
          <div className="">
            {week?.map((date, i) => (
              <Day key={i} date={date} />
            ))}
          </div>
        </div>
      </Container>
      {loading && <Loader />}
    </>
  );
}

export default Finances;
