import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { ChangeEvent, FormEvent, useMemo, useState } from "react";
import isEmail from "validator/lib/isEmail";
import Button from "./Button";
import Container from "./Container";
import Input, { InputField, InputGroup } from "./Input";
import Overlay from "./Overlay";

const Login = () => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    showPassword: false,
    error: "",
    forgotPasswordError: "",
    loading: {
      login: false,
      forgotPassword: false,
    },
  });

  const { email, password, showPassword, error, forgotPasswordError, loading } = state;

  const valid = useMemo(() => ({
    email: isEmail(email),
    password: password.length > 7,
    all: isEmail(email) && password.length > 7,
  }), [email, password]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value, error: "", forgotPasswordError: "" }));
  };

  const login = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!valid.all) return;

    setState((prevState) => ({ ...prevState, loading: { ...prevState.loading, login: true } }));
    console.info("logging in …");
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.info("logged in");
    } catch (err) {
      const error = err as { code?: string }; // Type assertion
      setState((prevState) => ({ ...prevState, error: error.code || "Unknown error occurred." }));
    } finally {
      setState((prevState) => ({ ...prevState, loading: { ...prevState.loading, login: false } }));
    }
  };

  const handleForgotPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isEmail(email)) return;

    setState((prevState) => ({ ...prevState, loading: { ...prevState.loading, forgotPassword: true } }));
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      setOverlayOpen(false);
    } catch (err) {
      const error = err as { code?: string }; // Type assertion
      setState((prevState) => ({ ...prevState, forgotPasswordError: error.code || "Unknown error occurred." }));
    } finally {
      setState((prevState) => ({ ...prevState, loading: { ...prevState.loading, forgotPassword: false } }));
    }
  };

  const toggleShowPassword = () => {
    setState((prevState) => ({ ...prevState, showPassword: !prevState.showPassword }));
  };

  return (
    <>
      <Container background>
        <form id="form-login" onSubmit={login}>
          <div className="flex flex-col gap-4 items-center">
            <div className="flex gap-2 flex-col md:flex-row w-full">
              <InputGroup>
                <Input
                  label="E-Mail"
                  type="email"
                  name="email"
                  value={email}
                  icon="mail"
                  onChange={handleInputChange}
                  error={error}
                />
                <Input
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  icon="lock"
                  onChange={handleInputChange}
                  buttons={{
                    icon: showPassword ? "eye" : "eye-off",
                    onClick: toggleShowPassword,
                  }}
                >
                  <div
                    className="text-xs px-2 text-primary cursor-pointer hover:underline inline-block self-start"
                    onClick={() => setOverlayOpen(true)}
                  >
                    Forgot Password
                  </div>
                </Input>
              </InputGroup>
            </div>
            <Button
              type="submit"
              disabled={!valid.all || loading.login}
              icon="signIn"
              isLoading={loading.login}
            >
              Login
            </Button>
          </div>
        </form>
      </Container>

      <Overlay id="overlay-forgot-password" isOpen={overlayOpen} setIsOpen={setOverlayOpen}>
        <form id="form-forgot-password" onSubmit={handleForgotPassword}>
          <div className="flex gap-2 flex-col p-4">
            <InputField
              label="E-Mail"
              error={forgotPasswordError}
              input={
                <Input
                  type="email"
                  form="form-forgot-password"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  buttons={{
                    icon: "send",
                    disabled: !valid.email,
                    isLoading: loading.forgotPassword,
                  }}
                />
              }
            />
          </div>
        </form>
      </Overlay>
    </>
  );
};

export default Login;
