import { Icon } from "@iconify/react";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { ChangeEvent, memo, MouseEvent, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useFlainContext } from "../../App";
import placeholder from "../../assets/placeholder.svg";
import Button from "../../components/Button";
import Headline from "../../components/Headline";
import Input, { InputField, InputGroup } from "../../components/Input";
import { AddButton } from "../../components/NavigationBar";
import Popover from "../../components/Popover";
import { getIcon } from "../../data/icons";
import { FlainContextType } from "../../data/types";
import { db, storage } from "../../firebase/firebase";
import { resize } from "../../helper/imageHelper";
import { Food, FoodImage, FoodType, foodTypes, UploadFood } from "./types";

const NewFood = () => {
  const [overlayOpen, setOverlayOpen] = useState(false);

  const { user } = useFlainContext() as FlainContextType;

  const defaultFood: Food = {
    id: "",
    name: "",
    price: 0,
    type: "order",
  };
  const [newFood, setNewFood] = useState<Food>(defaultFood);
  const [foodImage, setFoodImage] = useState<File | undefined>();

  const valid = useMemo(() => {
    const name = Boolean(newFood.name.trim());
    const price = newFood.price > 0;
    return {
      name: name,
      price: price,
      all: name && price,
    };
  }, [newFood.name, newFood.price]);

  const handleFoodName = (e: ChangeEvent<HTMLInputElement>) => setNewFood((prev) => ({ ...prev, name: e.target.value }));
  const handleFoodLink = (e: ChangeEvent<HTMLInputElement>) => setNewFood((prev) => ({ ...prev, link: e.target.value }));
  const handleFoodPrice = (e: ChangeEvent<HTMLInputElement>) => setNewFood((prev) => ({ ...prev, price: Number(e.target.value) }));
  const handleFoodType = (e: ChangeEvent<HTMLSelectElement>) => setNewFood((prev) => ({ ...prev, type: e.target.value as FoodType }));
  const handleFoodImage = (e: ChangeEvent<HTMLInputElement>) => setFoodImage(e.target.files?.[0]);

  const send = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!user) return;
    if (!valid.name) return console.warn("You need to enter a food name.");
    if (!valid.price) return console.warn("You need to enter a price.");

    let finalImage: FoodImage | undefined = undefined;

    if (foodImage) {
      const image = (await resize(foodImage, 1080)) as File;
      const thumb = (await resize(foodImage, 200)) as File;
      const id = new Date().getTime().toString();
      const imageName = image.name.split(".").shift();
      const thumbName = thumb.name.split(".").shift();
      const imageExtension = image.name.split(".").pop();
      const thumbExtension = thumb.name.split(".").pop();

      const finalImageName = `${imageName}-${id}.${imageExtension}`;
      const finalThumbName = `${thumbName}-${id}_thumb.${thumbExtension}`;

      finalImage = {
        name: finalImageName,
        path: `food/${finalImageName}`,
        thumb: {
          name: finalThumbName,
          path: `food/${finalThumbName}`,
        },
      };

      const imageRef = ref(storage, finalImage.path);
      const thumbRef = ref(storage, finalImage.thumb.path);

      await uploadBytes(imageRef, image).catch((error) => console.error(error));
      await uploadBytes(thumbRef, thumb).catch((error) => console.error(error));
    }

    const foodData: UploadFood = {
      name: newFood.name,
      price: newFood.price,
      type: newFood.type,
      image: finalImage,
      link: newFood.link,
    };

    await addDoc(collection(db, "food"), foodData)
      .then(() => {
        toast(`Submitted food "${newFood.name}"`);
        setNewFood(defaultFood);
        setFoodImage(undefined);
        setOverlayOpen(false);
      })
      .catch((error) => console.error(error.code, error.message));
  };

  return (
    <Popover id="overlay-new-food" isOpen={overlayOpen} setIsOpen={setOverlayOpen} trigger={<AddButton active={overlayOpen} />}>
      <div className="flex flex-col gap-2 items-center p-4 w-full">
        <Headline>New Food</Headline>
        <InputGroup>
          <InputField label="Name *" input={<Input value={newFood.name} onChange={handleFoodName} />} />
          <InputField label="Price *" input={<Input value={Number(newFood.price).toString()} onChange={handleFoodPrice} type="number" />} />
        </InputGroup>

        <InputGroup>
          <InputField label="Link" input={<Input value={newFood.link || ""} onChange={handleFoodLink} />} />
          <InputField
            label="Type"
            input={
              <select onChange={handleFoodType}>
                {Object.entries(foodTypes).map(([value, text]) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </select>
            }
          />
        </InputGroup>
        <InputGroup>
          <Input label="Image" type="file" onChange={handleFoodImage} accept="image/*" />
          {foodImage && (
            <InputField
              label="Preview"
              input={
                <div className="p-2 border border-border rounded-sm relative">
                  <div className="rounded-sm relative overflow-hidden">
                    <img src={foodImage ? URL.createObjectURL(foodImage) : placeholder} className="w-full aspect-video object-cover" alt="preview" />
                    {foodImage && (
                      <div
                        onClick={() => setFoodImage(undefined)}
                        className="opacity-0 hover:opacity-100 bg-black bg-opacity-70 transition-opacity duration-300 absolute top-0 left-0 w-full h-full flex items-center justify-center text-3xl"
                      >
                        <Icon icon={getIcon("x")} />
                      </div>
                    )}
                  </div>
                </div>
              }
            />
          )}
        </InputGroup>
        <hr />
        <Button onClick={send} icon="send" disabled={!valid.all}>
          Submit
        </Button>
      </div>
    </Popover>
  );
};

export default memo(NewFood);
