import { Dispatch } from "react";
import { ExpenseInterface } from "../types";

export interface StatisticsContextType {
  expenses: ExpenseInterface[];
  setExpenses: Dispatch<ExpenseInterface[]>;
}

export const colors = ["#d87c7c", "#919e8b", "#d7ab82", "#6e7074", "#61a0a8", "#efa18d", "#787464", "#cc7e63", "#724e58", "#4b565b"];
// export const colors2 = ["#4e79a7", "#f28e2c", "#e15759", "#76b7b2", "#59a14f", "#edc949", "#af7aa1", "#ff9da7", "#9c755f", "#bab0ab"];

export type LocalChartSeriesType = {
  id: string;
  data: Array<number | null>;
  color: string;
  label?: string;
};
