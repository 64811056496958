import { ChangeEvent, HTMLInputTypeAttribute, SetStateAction } from "react";
import Input, { InputButtonType } from "../../components/Input";

const SettingField = ({
  label,
  type,
  value,
  setValue,
  originalValue,
  edit,
  setEdit,
  handle,
  reset,
  error,
  buttons = [],
}: {
  label: string;
  type?: HTMLInputTypeAttribute;
  value: string;
  setValue: (value: SetStateAction<string>) => void;
  originalValue: string | null;
  edit: boolean;
  setEdit: (value: SetStateAction<boolean>) => void;
  handle: (e: ChangeEvent<HTMLInputElement>) => void;
  reset: () => void;
  error?: string;
  buttons?: InputButtonType[];
}) => {
  return (
    <Input
      value={value}
      disabled={!edit}
      onChange={handle}
      type={type}
      label={label}
      error={error}
      buttons={[
        ...buttons,
        {
          icon: "reset",
          onClick: () => reset(),
          disabled: edit && value === (originalValue || ""),
          hidden: !edit,
        },
        {
          icon: "x",
          onClick: () => setValue(""),
          disabled: value === "",
          hidden: !edit,
        },
        {
          icon: edit ? "edit-off" : "edit",
          onClick: () => (edit ? setEdit(false) : setEdit(true)),
        },
      ]}
    />
  );
};

export default SettingField;
