import ReminderList from "./ReminderList";

const Reminders = () => {
  return (
    <>
      <ReminderList />
    </>
  );
};

export default Reminders;
