import { createBrowserRouter, Outlet, RouteObject } from "react-router-dom";
import App from "../App";
import { IconType } from "../data/icons";
import NotFound from "./404";
import Finances from "./finances/Finances";
import Stats from "./finances/statistics/Statistics";
import Food from "./food/Food";
import Home from "./home/Home";
import Location from "./location/Location";
import Reminders from "./reminders/Reminders";
import Settings from "./settings/Settings";
import { SettingsAppearance } from "./settings/SettingsAppearance";
import SettingsNotifications from "./settings/SettingsNotifications";
import SettingsProfile from "./settings/SettingsProfile";
import SettingsTags from "./settings/SettingsTags";

// ROUTE
interface BaseRoute {
  text: string;
  icon: IconType;
  hiddenFromMenus?: boolean;
  removedFromRoutes?: boolean;
  children?: Routes | HomeRoutes | FinanceRoutes | SettingsRoutes;
}

export interface PathRoute extends BaseRoute {
  path: string;
  element: JSX.Element;
}

export interface IndexRoute extends BaseRoute {
  index: true;
  element: JSX.Element;
}

export type Route = PathRoute | IndexRoute;

// ROUTES
type HomeRoutes = {
  home: IndexRoute;
  finances: PathRoute & { children: FinanceRoutes };
  food: PathRoute;
  reminders: PathRoute;
  location: PathRoute;
  settings: PathRoute & { children: SettingsRoutes };
};

type SettingsRoutes = {
  index: IndexRoute;
  profile: PathRoute;
  notifications: PathRoute;
  appearance: PathRoute;
  tags: PathRoute;
};

type FinanceRoutes = {
  index: IndexRoute;
  statistics: PathRoute;
};

export interface Routes {
  index: PathRoute & { children: HomeRoutes };
  notFound: PathRoute;
}

const routes: Routes = {
  index: {
    path: "/",
    text: "Home",
    icon: "home",
    element: <App />,
    hiddenFromMenus: true,
    children: {
      home: {
        text: "Home",
        icon: "home",
        element: <Home />,
        hiddenFromMenus: true,
        index: true,
      },
      finances: {
        path: "/finances",
        text: "Finances",
        icon: "money",
        element: <Outlet />,
        children: {
          index: {
            text: "Finances",
            icon: "money",
            element: <Finances />,
            index: true,
          },
          statistics: {
            path: "/finances/statistics",
            text: "Statistics",
            icon: "chart",
            element: <Stats />,
          },
        },
      },
      food: {
        path: "/food",
        text: "Food",
        icon: "food",
        element: <Food />,
      },
      reminders: {
        path: "/reminders",
        text: "Reminders",
        icon: "todo",
        element: <Reminders />,
      },
      location: {
        path: "/location",
        text: "Location",
        icon: "location",
        element: <Location />,
        hiddenFromMenus: true,
        removedFromRoutes: true,
      },
      settings: {
        path: "/settings",
        text: "Settings",
        icon: "settings",
        element: <Outlet />,
        children: {
          index: {
            text: "Settings",
            icon: "settings",
            element: <Settings />,
            index: true,
          },
          profile: {
            path: "profile",
            text: "Profile",
            icon: "user",
            element: <SettingsProfile />,
          },
          notifications: {
            path: "notifications",
            text: "Notifications",
            icon: "notification",
            element: <SettingsNotifications />,
          },
          appearance: {
            path: "appearance",
            text: "Appearance",
            icon: "appearance",
            element: <SettingsAppearance />,
          },
          tags: {
            path: "tags",
            text: "Tags",
            icon: "tag",
            element: <SettingsTags />,
          },
        },
      },
    },
  },
  notFound: {
    path: "*",
    text: "404",
    icon: "404",
    element: <NotFound />,
    hiddenFromMenus: true,
  },
};

export const navigationRoutes: { [route: string]: PathRoute[] } = {
  finances: [routes.index.children!.finances, routes.index.children!.finances.children!.statistics],
};

export const containsPath = (route: PathRoute[], path: string): boolean => {
  return route.some((r) => r.path === path);
};

const routerRoute = (route: Route): RouteObject => {
  const { element, children } = route;
  const childrenArray = children ? Object.values(children).map(routerRoute) : undefined;

  if ("path" in route) {
    return {
      path: route.path,
      element,
      ...(childrenArray && { children: childrenArray }),
    } as RouteObject;
  } else {
    return {
      index: true,
      element,
      ...(childrenArray && { children: childrenArray }),
    } as RouteObject;
  }
};

export const getRoute = (pathname: string): Route | undefined => {
  const findInRoute = (route: Route): Route | undefined => {
    if ("path" in route && route.path === pathname) {
      return route;
    }

    if ("index" in route && route.index === true && pathname === "/") {
      return route;
    }

    if (route.children) {
      for (const child of Object.values(route.children)) {
        const result = findInRoute(child);
        if (result) {
          return result;
        }
      }
    }

    return undefined;
  };

  for (const topLevelRoute of Object.values(routes)) {
    const result = findInRoute(topLevelRoute);
    if (result) return result;
  }

  return undefined;
};

export const router = createBrowserRouter([
  routerRoute(routes.index), // Home and its nested routes
  routerRoute(routes.notFound), // 404 route
]);

export default routes;
