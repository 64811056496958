import { getIcon } from "@iconify/react";
import { BarSeriesType } from "@mui/x-charts";
import { useEffect, useState } from "react";
import { ExpenseInterface } from "../routes/finances/types";

export const toggleIcon = (target: Element, icon?: string) => {
  const originalIconContainer = target.firstChild as HTMLElement;

  if (originalIconContainer) {
    const originalIconSVG = originalIconContainer.innerHTML;

    const temporaryIconSVG = getIcon(icon || "fluent:checkmark-24-regular")?.body;

    if (temporaryIconSVG) {
      originalIconContainer.innerHTML = temporaryIconSVG;

      setTimeout(() => {
        originalIconContainer.innerHTML = originalIconSVG;
      }, 2000);
    } else console.error(`toggleIcon: icon "${icon}" does not exist.`);
  } else console.error("toggleIcon: container does not exist.");
};

export const useIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return isMobile;
};

export function generateExpenses(
  e: ExpenseInterface[],
  date: Date
): {
  daysOfMonth: number[];
  expensesOfMonthByDay: number[];
  expensesOfMonthWithNull: (number | null)[];
  cumulativeExpensesOfMonth: number[];
  daysOfYear: number[];
  expensesOfYearByDay: number[];
  cumulativeExpensesOfYear: number[];
} {
  const monthDays: number[] = [];
  const yearDays: number[] = [];
  const expensesOfMonthByDay: number[] = [];
  const expensesOfYearByDay: number[] = [];
  const cumulativeExpensesOfMonth: number[] = [];
  const cumulativeExpensesOfYear: number[] = [];

  const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const isLeapYear = new Date(date.getFullYear(), 1, 29).getDate() === 29;
  const daysInYear = isLeapYear ? 366 : 365;

  for (let i = 1; i <= daysInMonth; i++) {
    monthDays.push(i);
    expensesOfMonthByDay.push(0);
    cumulativeExpensesOfMonth.push(0);
  }
  for (let i = 1; i <= daysInYear; i++) {
    yearDays.push(i);
    expensesOfYearByDay.push(0);
    cumulativeExpensesOfYear.push(0);
  }

  const getDayOfYear = (date: Date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 0);
    const diff = date.getTime() - startOfYear.getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
  };

  e.forEach((expense) => {
    const boughtDate = expense.date.bought.toDate();

    // Assign expenses to the month
    if (boughtDate.getMonth() === date.getMonth() && boughtDate.getFullYear() === date.getFullYear()) {
      const dayOfMonth = boughtDate.getDate();
      expensesOfMonthByDay[dayOfMonth - 1] += expense.price;
    }

    // Assign expenses to the year
    if (boughtDate.getFullYear() === date.getFullYear()) {
      const dayOfYear = getDayOfYear(boughtDate);
      expensesOfYearByDay[dayOfYear - 1] += expense.price;
    }
  });

  // Calculate cumulative monthly expenses
  expensesOfMonthByDay.reduce((acc, curr, index) => {
    cumulativeExpensesOfMonth[index] = acc + curr;
    return cumulativeExpensesOfMonth[index];
  }, 0);

  // Calculate cumulative yearly expenses
  expensesOfYearByDay.reduce((acc, curr, index) => {
    cumulativeExpensesOfYear[index] = acc + curr;
    return cumulativeExpensesOfYear[index];
  }, 0);

  const expensesOfMonthWithNull = expensesOfMonthByDay.map((expense) => (expense === 0 ? null : expense));

  return {
    daysOfMonth: monthDays,
    expensesOfMonthByDay: expensesOfMonthByDay,
    expensesOfMonthWithNull: expensesOfMonthWithNull,
    cumulativeExpensesOfMonth: cumulativeExpensesOfMonth,
    daysOfYear: yearDays,
    expensesOfYearByDay: expensesOfYearByDay,
    cumulativeExpensesOfYear: cumulativeExpensesOfYear,
  };
}

export const createSeries = (data: (number | null)[], id: string): BarSeriesType => {
  return {
    data: data,
    id: `${id.toLowerCase()}-expenses`,
    stack: "a",
    label: id,
    highlightScope: {
      highlight: "series",
      fade: "global",
    },
  } as BarSeriesType;
};
