import { ComponentPropsWithoutRef, ReactNode } from "react";

interface ContainerProps extends ComponentPropsWithoutRef<"div"> {
  children: ReactNode;
  className?: string;
  small?: boolean;
  noPadding?: boolean;
  background?: boolean;
}

const Container = ({ children, className = "", small = false, noPadding = false, background = false, ...props }: ContainerProps) => (
  <div
    {...props}
    className={`
      w-full mx-auto my-4 px-0 sm:px-4
      ${small ? "max-w-md" : "max-w-screen-sm"}
    `}
  >
    <div
      className={`
        rounded-sm
        ${background ? "bg-gradient-to-b from-surface to-surface/50" : ""}
        
      `}
    >
      <div
        className={`${background ? "bg-noise" : ""} ${noPadding ? "" : "p-4"} 
        ${className}`}
      >
        {children}
      </div>
    </div>
  </div>
);

export default Container;
