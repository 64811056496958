import { addDoc, arrayUnion, collection, doc, onSnapshot, Timestamp, Unsubscribe, updateDoc } from "firebase/firestore";
import { ChangeEvent, KeyboardEvent, memo, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFlainContext } from "../../App";
import Button from "../../components/Button";
import Headline from "../../components/Headline";
import Input, { InputField, InputGroup } from "../../components/Input";
import { AddButton } from "../../components/NavigationBar";
import Popover from "../../components/Popover";
import Tag from "../../components/Tag";
import { FlainContextType } from "../../data/types";
import { db } from "../../firebase/firebase";
import { timeZero, toDateInputValue } from "../../helper/dateHelper";
import routes from "../routes";
import { Expense, Person, Price, TagGroup, Tag as TagType } from "./types";

const NewExpense = () => {
  const { user } = useFlainContext() as FlainContextType;

  const [overlayOpen, setOverlayOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState<Date>(new Date());
  const [price, setPrice] = useState<Price>(0);
  const [expense, setExpense] = useState<Expense>("");
  const [relationFrom, setRelationFrom] = useState<Person>();
  const [relationFor, setRelationFor] = useState<Person>();

  const [tag, setTag] = useState<TagType>("");
  const [tags, setTags] = useState<TagType[]>([]);
  const [tagGroupNames, setTagGroupNames] = useState<string[]>([]);
  const [tagGroups, setTagGroups] = useState<TagGroup[]>([]);
  const [activeTags, setActiveTags] = useState<string[]>([]);
  const [newTagGroup, setNewTagGroup] = useState<string>("");

  const [expensesList, setExpensesList] = useState<string[]>([]);
  const [peopleList, setPeopleList] = useState<string[]>([]);

  const valid = useMemo(() => {
    const dateValid = Boolean(date);
    const priceValid = price > 0;
    const expenseValid = Boolean(expense);
    const tagsValid = activeTags.length > 0;
    return {
      date: dateValid,
      price: priceValid,
      expense: expenseValid,
      tags: tagsValid,
      all: dateValid && priceValid && expenseValid && tagsValid,
    };
  }, [date, expense, activeTags, price]);

  useEffect(() => {
    if (!user) return;

    const subscriptions: Unsubscribe[] = [];

    const unsubExpenses = onSnapshot(doc(db, "meta", "expenses"), (doc) => {
      setExpensesList((doc.data()?.list as string[]) || []);
    });
    const unsubscribePeople = onSnapshot(doc(db, "meta", "people"), (doc) => {
      setPeopleList((doc.data()?.list as string[]) || []);
    });
    const unsubscribeTags = onSnapshot(doc(db, "meta", "tags"), (doc) => {
      const groups = doc.data() as TagGroup[];
      const groupNames = Object.keys(groups).flat().sort();
      const t = Object.values(groups).flat().sort() as TagType[];
      setTags(t);
      setTagGroupNames(groupNames);
      setTagGroups(groups);
    });

    subscriptions.push(unsubExpenses, unsubscribePeople, unsubscribeTags);

    return () => {
      subscriptions.forEach((unSub) => unSub());
    };
  }, [user]);

  const upload = async () => {
    if (!user || !valid.all) return console.error("Invalid input.");

    setIsLoading(true);
    try {
      const expenseData = {
        date: {
          bought: Timestamp.fromDate(timeZero(date)),
          created: Timestamp.fromDate(new Date()),
        },
        price,
        expense,
        relationFrom,
        relationFor,
        tags: activeTags,
      };

      await addDoc(collection(db, "expenses"), expenseData);

      const batchUpdates = [
        updateDoc(doc(db, "meta", "expenses"), {
          list: arrayUnion(expense),
        }),
        relationFrom &&
          updateDoc(doc(db, "meta", "people"), {
            list: arrayUnion(relationFrom),
          }),
        relationFor &&
          updateDoc(doc(db, "meta", "people"), {
            list: arrayUnion(relationFor),
          }),
        activeTags.length > 0 &&
          updateDoc(doc(db, "meta", "tags"), {
            unsorted: arrayUnion(...activeTags.filter((t) => !tags.includes(t))),
          }),
      ];

      await Promise.all(batchUpdates.filter(Boolean));

      // Reset form
      setPrice(0);
      setDate(new Date());
      setExpense("");
      setRelationFrom(undefined);
      setRelationFor(undefined);
      setActiveTags([]);
      setOverlayOpen(false);

      console.info("Expense uploaded successfully.");
    } catch (error) {
      console.error("Error uploading expense:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDate = (e: ChangeEvent<HTMLInputElement>) => {
    const tempDate = e.target.value;
    if (tempDate) setDate(new Date(tempDate));
  };

  const handlePrice = (value: string) => setPrice(Number(value));

  const handleExpense = (value: string) => setExpense(value);

  const handleRelationFrom = (value: string) => {
    setRelationFrom(value?.trim() ? value : undefined);
  };

  const handleRelationFor = (value: string) => {
    setRelationFor(value?.trim() ? value : undefined);
  };

  const handleTags = (value: string) => setTag(value);

  const handleNewTag = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const value = (e.target as HTMLInputElement).value;
      if (!activeTags.includes(value)) {
        setActiveTags((t) => [...t, value]);
        setTag("");
      }
    }
  };

  const addTag = (value: string) => {
    if (!activeTags.includes(value)) {
      setActiveTags((t) => [...t, value]);
      setTag("");
    }
  };

  const removeTag = (value: string) => {
    if (activeTags.includes(value)) {
      const filtered = activeTags.filter((t) => t !== value);
      setActiveTags(filtered);
    }
  };

  useEffect(() => {
    if (!tagGroups?.length) return;
    const g = tagGroups.find((g) => g.includes(tag));
    const group = g && Object.keys(g);
    console.log("group: ", group);
    //const group = tagGroups.find(g => g.includes(tag))
    //setNewTagGroup()
  }, [newTagGroup, tag, tagGroups]);

  const parentRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();
  if (pathname !== routes.index.children.finances.path) return null;
  return (
    <Popover id="overlay-new-expense" isOpen={overlayOpen} setIsOpen={setOverlayOpen} trigger={<AddButton active={overlayOpen} />}>
      <div className="flex flex-col items-center gap-2 p-4" ref={parentRef}>
        <Headline>New Expense</Headline>
        <InputGroup>
          <Input label="Date" type="date" value={toDateInputValue(date)} onChange={handleDate} />
        </InputGroup>
        <hr />
        <InputGroup>
          <InputField
            label="Expense"
            input={
              <Input
                value={expense}
                onChange={(e) => handleExpense(e.target.value)}
                id="expense"
                dataList={{ list: expensesList, parentRef: parentRef, onClick: handleExpense }}
              />
            }
          />
          <InputField label="Price" input={<Input type="number" value={Number(price).toString()} onChange={(e) => handlePrice(e.target.value)} min={0} />} />
        </InputGroup>
        <hr />
        <InputGroup>
          <Input
            label="From"
            value={relationFrom}
            id="relation-from"
            onChange={(e) => handleRelationFrom(e.target.value)}
            dataList={{ list: peopleList, parentRef: parentRef, onClick: handleRelationFrom }}
          />
          <Input
            label="For"
            value={relationFor}
            id="relation-for"
            onChange={(e) => handleRelationFor(e.target.value)}
            dataList={{ list: peopleList, parentRef: parentRef, onClick: handleRelationFor }}
          />
        </InputGroup>
        <hr />
        <InputField
          label="Tags"
          input={
            <>
              {activeTags?.length > 0 && (
                <div className="flex flex-wrap gap-1 w-full">
                  {activeTags?.map((t) => (
                    <Tag key={t} active onClick={() => removeTag(t)}>
                      {t}
                    </Tag>
                  ))}
                </div>
              )}
              <Input
                value={tag}
                id="tags"
                onKeyDown={handleNewTag}
                onChange={(e) => handleTags(e.target.value)}
                dataList={{ list: tags, parentRef: parentRef, onClick: addTag }}
              />
            </>
          }
        />
        <Input
          id="new-tag-group"
          value={newTagGroup}
          onChange={(e) => setNewTagGroup(e.target.value)}
          dataList={{ list: tagGroupNames, parentRef: parentRef, onClick: setNewTagGroup }}
        />
        <hr />
        <Button onClick={upload} disabled={!valid.all || isLoading} icon="upload">
          Submit
        </Button>
      </div>
    </Popover>
  );
};

export default memo(NewExpense);
