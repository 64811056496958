import Resizer from "react-image-file-resizer";

export const resize = (file: File, size: number) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      "webp",
      100,
      0,
      (uri) => resolve(uri),
      "file"
    )
  })
}