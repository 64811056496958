import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { arrayUnion, doc, initializeFirestore, persistentLocalCache, setDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getMessaging, getToken } from "firebase/messaging";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBpBjQfkkgjHOnW4eJlUqrFhMR06t7X7jI",
  authDomain: "finances-27911.firebaseapp.com",
  projectId: "finances-27911",
  storageBucket: "finances-27911.appspot.com",
  messagingSenderId: "739882983022",
  appId: "1:739882983022:web:4019213aaa0f024141107f",
};

export const messagingKey = "BOx2mYuTeQDu44Nzx8e78czMyZW5ZxPyXW_vxssl98yec0tAGdnYT8PFcEmNakKZ7dmSP894-x8VvdrDObHMZGc";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {
  localCache: persistentLocalCache(),
  ignoreUndefinedProperties: true,
});
export const messaging = getMessaging(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, "asia-southeast1");
export const auth = getAuth();

export const logout = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      console.info("signed out");
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export const getCurrentToken = async (): Promise<string | undefined> => {
  if (auth.currentUser && Notification.permission === "granted") {
    return await getToken(messaging, { vapidKey: messagingKey })
      .then((token) => token)
      .catch((error) => error);
  }
  return undefined;
};

export const writeCurrentTokenToDatabase = async () => {
  if (auth.currentUser && Notification.permission === "granted") {
    const token = await getToken(messaging, { vapidKey: messagingKey }).catch((error) => console.error(error));
    if (token && auth.currentUser) {
      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        notificationTokens: arrayUnion(token),
      }).catch(async (error) => {
        console.error(error);
        console.info("User document does not exist. Creating new one.");
        if (token && auth.currentUser) {
          await setDoc(doc(db, "users", auth.currentUser.uid), {
            notificationTokens: arrayUnion(token),
            displayName: auth.currentUser?.displayName,
          }).catch((error) => console.error(error));
        }
      });
    }
  }
};

export const handleNotificationPermission = () =>
  new Promise((resolve) => {
    Notification.requestPermission()
      .then(async (permission) => {
        if (permission === "granted") {
          await writeCurrentTokenToDatabase();
        }
        return resolve(permission); // Return the permission here
      })
      .catch(() => resolve(Notification.permission));
  });

export const sendNotification = async (recipients: (string | undefined)[], title: string, body: string, url?: string) => {
  const notify = httpsCallable(functions, "sendNotification");
  notify({ recipients: recipients, title: title, body: body, url: url })
    .then((result: any) => {
      /** @type {any} */
      if (result) console.log(result);
    })
    .catch((error) => {
      console.error(error.code, error.message, error.details);
    });
};
